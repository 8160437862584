import { createSlice } from "@reduxjs/toolkit";

const initialState = { privileges: [] };

export const privilegeSlice = createSlice({
  name: "privileges",
  initialState,
  reducers: {
    setPrivileges: (state, action) => {
      state.privileges = action.payload.map((item) => item.name);
    },
  },
});

export const { setPrivileges } = privilegeSlice.actions;

export default privilegeSlice.reducer;
