import axios from "axios";
import { BASE_URL } from "../constants";
import { isValidToken, setSession } from "../utils/jwt";
import { createContext, useEffect, useReducer } from "react";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

export const initialState = {
  user: null,
  isInitialized: false,
  isAuthenticated: false,
};

export const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };

    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case SIGN_OUT:
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const config = {};

  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get(`${BASE_URL}api/auth/me`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const {
            payload: { user },
          } = response.data;

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (error) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize().catch(console.error);
  }, []);

  const signIn = async (email, password) => {
    const data = {
      email,
      password,
    };

    const response = await axios.post(`${BASE_URL}api/auth/signin`, data);

    const {
      payload: { accessToken, user },
    } = response.data;

    console.log(
      "sing-in [res]: ",
      response.data.payload.user.roles[0].roleType
    );

    if (response.data.payload.user.roles[0].roleType === "ADMIN") {
      setSession(accessToken);
      dispatch({
        type: SIGN_IN,
        payload: {
          user,
        },
      });
    } else {
      setSession(null);
      dispatch({ type: SIGN_OUT });
    }
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (name, email, password, essNumber, invitationToken) => {
    // let response;
    // if (invitationToken) {
    //   response = await axios.post(`${BASE_URL}api/auth/invitation/signup`, {
    //     name,
    //     password,
    //     invitationToken,
    //   });
    // } else {
    //   response = await axios.post(`${BASE_URL}api/auth/signup`, {
    //     name,
    //     email,
    //     password,
    //     essNumber,
    //   });
    // }

    const response = await axios.post(`${BASE_URL}api/auth/signup`, {
      name,
      email,
      password,
      essNumber,
    });

    // const { user } = response.data;
    const { accessToken, user } = response.data;
    window.localStorage.setItem("accessToken", accessToken);

    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        state,
        ...state,
        dispatch,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
