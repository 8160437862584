import { React, useEffect, useState } from "react";
import { Edit } from "react-feather";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Box,
  Link,
  Card,
  Divider,
  Stack,
  Button,
  Typography,
  Breadcrumbs,
  TextField as MuiTextField,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getAdminInfo, updateAdmin } from "../../api/admin";
import useAuth from "../../hooks/useAuth";
import { useNavigate, NavLink } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";
import { uploadFile } from "../../api/file";
import ImageUpload from "../../components/ui/ImageUpload";
import ErrorAlert from "../../components/ui/ErrorAlert";
import { RemoveRedEyeRounded, VisibilityOffRounded } from "@mui/icons-material";

const TextField = styled(MuiTextField)(spacing);

const AdminProfileUpdate = () => {
  const [passType, setPassType] = useState("password");
  const [errorMessage, setErrorMessage] = useState("");
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);
  const [adminData, setAdminData] = useState({});

  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  // functions
  const fetchAdminInfo = async () => {
    try {
      let response = await getAdminInfo();

      let data = (await response?.data?.payload) ?? {};

      setAdminData(data);
    } catch (error) {
      setErrorMessage(
        error.response.data.message ??
          "Something went wrong, please try again later"
      );
    }
  };

  const gotoProfileHandler = () => {
    navigate("/admin/profile", { replace: true });
  };

  // password hide/show icon
  const handleShowPassword = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };

  const handleSignOut = async () => {
    await signOut();
    navigate("/auth/sign-in");
  };

  useEffect(() => {
    fetchAdminInfo();
    setHideDeleteBtn(user?.displayPhoto ? false : true);
  }, [user?.displayPhoto]);

  if (!user) {
    return "";
  }

  return (
    <div>
      <Helmet title="Profile" />

      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={3}
      >
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            display="flex"
            alignItems="center"
          >
            <span>{`${user?.firstName ?? ""} ${user.lastName}`}</span>
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Link component={NavLink} to="/admin/all">
              Admins
            </Link>
            <Typography>Profile Update</Typography>
          </Breadcrumbs>
        </Box>

        <Button variant="outlined" onClick={gotoProfileHandler}>
          Go Back To Admin Profile
        </Button>
      </Stack>

      <Divider />
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={8} xl={6}>
          <Card sx={{ mt: 4, p: { xs: 5, md: 10 } }}>
            <Typography variant="h2" align={"center"}>
              Update Profile
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Formik
              initialValues={{
                image: adminData?.displayPhoto ?? "",
                firstName: adminData?.firstName ?? "",
                middleName: adminData?.middleName ?? "",
                lastName: adminData?.lastName ?? "",
                email: adminData?.email ?? "",
              }}
              validationSchema={Yup.object().shape({
                image: Yup.mixed().nullable().required("Image is required"),
                firstName: Yup.string().required("First Name is required"),
                email: Yup.string().email().required("Email is required"),
              })}
              enableReinitialize
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting }
              ) => {
                const formData = new FormData();
                formData.append("file", values.image);
                formData.append("fileType ", "USER_IMAGE");
                formData.append("fileUploadType", "USER_IMAGE");

                if (values.image && !values.image.fileUrl) {
                  try {
                    let response = await uploadFile(formData);

                    const data = {
                      dbFileId: response?.data?.payload?.id ?? 0,
                      firstName: values?.firstName ?? "",
                      middleName: values?.middleName ?? "",
                      lastName: values?.lastName ?? "",
                      email: values?.email ?? "",
                      password: values?.password,
                    };

                    try {
                      await updateAdmin(data);

                      if (values?.password?.length > 0) {
                        handleSignOut();
                      }

                      return navigate("/admin/profile");
                    } catch (error) {
                      setErrorMessage(
                        error.response.data.message ||
                          "Something went wrong, please try again later"
                      );
                    }
                  } catch (error) {
                    setErrorMessage(
                      error.response.data.message ||
                        "Something went wrong, please try again later"
                    );
                  }
                } else {
                  const data = {
                    dbFileId: user?.displayPhoto.id ?? 0,
                    firstName: values?.firstName ?? "",
                    middleName: values?.middleName ?? "",
                    lastName: values?.lastName ?? "",
                    email: values?.email ?? "",
                    password: values?.password,
                  };

                  try {
                    await updateAdmin(data);

                    if (values?.password?.length > 0) {
                      handleSignOut();
                    }

                    return navigate("/admin/profile");
                  } catch (error) {
                    setErrorMessage(
                      error.response.data.message ||
                        "Something went wrong, please try again later"
                    );
                  }
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                resetForm,
                setFieldValue,
              }) => (
                <form noValidate onSubmit={handleSubmit}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}

                  {/* image  */}
                  <FormControl sx={{ display: "flex" }}>
                    <ImageUpload
                      name="image"
                      hideDeleteBtn={hideDeleteBtn}
                      onRemove={() => {
                        setFieldValue("image", null);
                        setHideDeleteBtn(true);
                      }}
                      onChange={(event) => {
                        setFieldValue("image", event.target.files[0]);
                        setHideDeleteBtn(false);
                      }}
                      imgSrc={
                        values?.image?.fileUrl
                          ? values?.image?.fileUrl
                          : values.image
                          ? URL.createObjectURL(values.image)
                          : null
                      }
                    />

                    {Boolean(errors.image && touched.image) && (
                      <FormHelperText error>{errors.image}</FormHelperText>
                    )}
                  </FormControl>

                  {/* first name */}
                  <TextField
                    name="firstName"
                    label="First Name"
                    value={values.firstName || ""}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    size="small"
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: 5 }}
                    my={3}
                  />

                  {/* middle-name */}
                  <TextField
                    name="middleName"
                    label="Middle Name"
                    value={values.middleName || ""}
                    error={Boolean(touched.middleName && errors.middleName)}
                    fullWidth
                    size="small"
                    helperText={touched.middleName && errors.middleName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: 5 }}
                    my={3}
                  />

                  {/* last name */}
                  <TextField
                    name="lastName"
                    label="Last Name"
                    value={values.lastName || ""}
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    size="small"
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: 5 }}
                    my={3}
                  />

                  {/* email */}
                  <TextField
                    type="email"
                    name="email"
                    label="Email"
                    value={values.email || ""}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    size="small"
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    sx={{ mt: 5 }}
                    my={3}
                  />

                  {/* password */}
                  <TextField
                    id="password"
                    variant="outlined"
                    name="password"
                    size="small"
                    fullWidth
                    type={passType}
                    onChange={handleChange}
                    placeholder="Enter Password"
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment:
                        passType === "password" ? (
                          <RemoveRedEyeRounded
                            color="secondary"
                            onClick={handleShowPassword}
                            sx={{ cursor: "default" }}
                          />
                        ) : (
                          <VisibilityOffRounded
                            color="secondary"
                            onClick={handleShowPassword}
                            sx={{ cursor: "default" }}
                          />
                        ),
                    }}
                    value={values.password || ""}
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    onBlur={handleBlur}
                    sx={{ mt: 5 }}
                    my={3}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    size="small"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    sx={{ mt: 2, mb: 2 }}
                  >
                    Update Admin
                  </Button>
                </form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminProfileUpdate;
