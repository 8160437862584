import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
  Card as MuiCard,
  Chip as MuiChip,
  Paper as MuiPaper,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getAllUser } from "../../../api/user";
import params from "../../../utils/params";
import ContentLoader from "../../../components/ui/ContentLoader";
import NotFound from "../../../components/ui/NotFound";
import LatestUserTable from "../../../components/table/LatestUserTable";

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)`
  height: 20px;
  padding: 4px 0;
  font-size: 90%;
  background-color: ${(props) =>
    props.theme.palette[props.color ? props.color : "primary"].light};
  color: ${(props) => props.theme.palette.common.white};
`;

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

// Data
let id = 0;
function createData(name, start, end, state, assignee) {
  id += 1;
  return { id, name, start, end, state, assignee };
}

const rows = [
  createData(
    "Project Aurora",
    "01/01/2021",
    "31/06/2021",
    <Chip label="Done" color="success" />,
    "James Dalton"
  ),
  createData(
    "Project Eagle",
    "01/01/2021",
    "31/06/2021",
    <Chip label="In Progress" color="warning" />,
    "Tracy Mack"
  ),
  createData(
    "Project Fireball",
    "01/01/2021",
    "31/06/2021",
    <Chip label="Done" color="success" />,
    "Sallie Love"
  ),
  createData(
    "Project Omega",
    "01/01/2021",
    "31/06/2021",
    <Chip label="Cancelled" color="error" />,
    "Glenda Jang"
  ),
  createData(
    "Project Yoda",
    "01/01/2021",
    "31/06/2021",
    <Chip label="Done" color="success" />,
    "Raymond Ennis"
  ),
  createData(
    "Project Zulu",
    "01/01/2021",
    "31/06/2021",
    <Chip label="Done" color="success" />,
    "Matthew Winters"
  ),
];

const DashboardTable = () => {
  // states
  const [latestUser, setLatestUser] = useState([]);
  const [loading, setLoading] = useState(true);

  // function
  const fetchLatestUser = async () => {
    setLoading(true);

    try {
      let response = await getAllUser(
        params({
          pageSize: 5,
          sortBy: "creationDate",
          ascOrDesc: "desc",
        })
      );

      let data = response?.data?.payload?.content ?? [];
      setLatestUser(data);
      setLoading(false);
    } catch (error) {
      console.log(
        "Error: ",
        error?.response?.data?.message ?? "Something went wrong!"
      );
    }
  };

  useEffect(() => {
    fetchLatestUser();
  }, []);
  return (
    <Card mb={6} p={5}>
      <Typography variant="h5" mb={3}>
        Latest User
      </Typography>

      <Paper>
        <TableWrapper>
          {loading ? (
            <ContentLoader />
          ) : (
            <>
              {latestUser.length < 1 ? (
                <NotFound />
              ) : (
                <LatestUserTable latestUser={latestUser} />
              )}
            </>
          )}
        </TableWrapper>
      </Paper>
    </Card>
  );
};

export default DashboardTable;
