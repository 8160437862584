import axios from "../utils/axios";

// get all event
export const getAllEvents = (filters) => {
  return axios.get(`/api/events/all?${filters}`);
};

// create event
export const createEvent = (data) => {
  return axios.post("/api/events/create", data);
};

// udpate event
export const updateEvent = (data) => {
  return axios.put("/api/events/update", data);
};

// get specific event
export const getEvent = (id) => {
  return axios.get(`/api/events/eventId/${id}`);
};

// delete event
export const deleteEvent = (id) => {
  return axios.delete(`/api/events/delete/id/${id}`);
};

//event participants
export const getEventParticipants = (filters, id) => {
  return axios.get(`/api/events/purchased-users/eventId/${id}?${filters}`);
};

// events ticket related api's
// ---------------------------
// get events ticket count
export const getEventsTicketCount = (eventId) => {
  return axios.get(`/api/events/ticket-count/eventId/${eventId}`);
};

// get specific events purchased tickets
export const getEventsPurchasedTicket = (eventId) => {
  return axios.get(`/api/events/tickets/eventId/${eventId}`);
};

// add event notification
export const addEventNotification = (data, eventId) => {
  return axios.post(`/api/events/send-notification/eventId/${eventId}/`, data);
};

//add participant notification of an event
export const addParticipantNotification = (data, eventId, userId) => {
  return axios.post(
    `/api/events/send-notification/eventId/${eventId}/user/${userId}`,
    data
  );
};
