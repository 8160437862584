import React from "react";
import { useDispatch } from "react-redux";
import { message as snackbarMsg } from "../redux/slices/notificationUi";

export function useMessage() {
  const dispatch = useDispatch();
  return (message, type) => {
    dispatch(snackbarMsg({ open: true, message: message, type: type }));
  };
}
