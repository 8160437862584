import axios from "../utils/axios";

// get all blogs
export const getAllBlogs = (filters) => {
  return axios.get(`api/blogs/all?${filters}`);
};

// get post by blog type
export const getAllPostByBlogType = (blogType) => {
  return axios.get(`api/blogs/blogtype/${blogType}`);
};

// create blog
export const createBlog = (data) => {
  return axios.post("api/blogs/create", data);
};

// delete blog by uuid
export const deleteBlog = (uuid) => {
  return axios.delete(`api/blogs/delete/uuid/${uuid}`);
};

// get specific blog
export const getBlogById = (id) => {
  return axios.get(`api/blogs/id/${id}`);
};

export const getBlogByUUID = (uuid) => {
  return axios.get(`api/blogs/uuid/${uuid}`);
};

// update blog
export const updateBlog = (data) => {
  return axios.put("api/blogs/update", data);
};
