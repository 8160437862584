import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function ContentLoader() {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <CircularProgress color="primary" sx={{ my: 5 }} />
    </Box>
  );
}
