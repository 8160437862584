import React from "react";

import {
  User,
  CreditCard,
  Tv,
  ShoppingCart,
  UserPlus,
  Server,
} from "react-feather";

export default function useNotification() {
  const getNotificationIcon = (iconType) => {
    switch (iconType) {
      case "EVENT":
        return Server;

      case "SUBSCRIPTION_EXPIRING":
        return CreditCard;

      case "SUBSCRIPTION_EXPIRED":
        return CreditCard;

      case "NEWS":
        return Tv;

      case "USER_SIGNUP":
        return User;

      case "USER_SUBSCRIPTION":
        return UserPlus;

      case "ORDER":
        return ShoppingCart;

      default:
        return Server;
    }
  };

  const getNotificationUrl = (notificationType) => {
    switch (notificationType) {
      case "EVENT":
        return "/event/all";

      case "SUBSCRIPTION_EXPIRING":
        return "/subscription/all";

      case "SUBSCRIPTION_EXPIRED":
        return "/subscription/all";

      case "NEWS":
        return "/";

      case "USER_SIGNUP":
        return "/user/all";

      case "USER_SUBSCRIPTION":
        return "/subscription/all";

      case "ORDER":
        return "/order/all";
      case "TICKET":
        return "/event/ticket/all";
      default:
        return "/";
    }
  };

  return { getNotificationIcon, getNotificationUrl };
}
