import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

//reducers
import counterReducer from "./slices/counter";
import notificationReducer from "./slices/notificationUi";
import blogReducer from "./slices/blogSlice";
import privilegeReducer from "./slices/privilegeSlice";

const reducers = combineReducers({
  counter: counterReducer,
  message: notificationReducer,
  blog: blogReducer,
  privileges: privilegeReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "privileges"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});
