import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import PhotoAlbumIcon from "@mui/icons-material/PhotoAlbum";
import { Edit, Delete } from "@mui/icons-material";
import {
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  activeIndicateColor,
  inActiveIndicateColor,
  imageDefaultStyleCover,
} from "../../theme/globalStyles";
import HtmlParser from "react-html-parser";
import ViewBoardMember from "../modals/ViewBoardMember";
import { getAlbumById, updateAlbum } from "../../api/album";
import { getAllMemory } from "../../api/memory";
import TimeFormat from "../../utils/timeFormat";

export default function MemoryTable(props) {
  // states
  // const [album, setAlbum] = useState({});

  // objects
  const { allMemory, deleteMemory, showSetCoverImageModal } = props;
  const navigate = useNavigate();

  const bla = (memoryId, albumId, imageId) => {
    // get the specific albub data
    getAlbumById(albumId)
      .then((res) => {
        const responseData = res.data.payload;
        console.log("Album response from memory: ", responseData);

        // set data for update
        let data = {
          active: responseData.active,
          albumId: albumId,
          description: responseData.description,
          memoryPhotoId: memoryId,
          name: responseData.name,
        };

        updateAlbum(data)
          .then((res) => console.log("Successfully Set Album Cover"))
          .catch((error) =>
            console.log(`Error: ${error.response.data.message}`)
          );
      })
      .catch((error) => console.log(`Error: ${error.response.data.message}`));
  };

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px",
          },
          // "table, td, th": {
          //   borderRight: "1px solid #d9dede",
          //   "&:last-of-type": {
          //     borderRight: "none",
          //   },
          // },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Caption</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
              Created At
            </TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allMemory?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                <Box
                  sx={{
                    height: "100px",
                    width: "150px",
                    borderRadius: "8px",
                    overflow: "hidden",
                  }}
                >
                  <img
                    src={row?.image?.fileUrl ?? "/default-image.png"}
                    alt={row?.image?.fileName ?? row?.name}
                    style={{
                      ...imageDefaultStyleCover,
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                {row.caption ?? "Not Available"}
              </TableCell>

              <TableCell align="left">{HtmlParser(row.description)}</TableCell>
              <TableCell align="left">
                {TimeFormat(row.creationDateTimeStamp)}
              </TableCell>
              <TableCell align="center">
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    color="primary"
                    variant="outlined"
                    sx={{ ...sxTableActionButtonBorder }}
                    onClick={() => navigate(`/memory/edit/${row.id}`)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    sx={{ ...sxTableActionButtonBorderRed }}
                    onClick={() => deleteMemory(row.id)}
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    color="secondary"
                    sx={{ ...sxTableActionButtonBorder }}
                    onClick={() =>
                      showSetCoverImageModal(row?.id, row?.albumId)
                    }
                  >
                    <PhotoAlbumIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
