export const parameters = (param) => {
  Object.keys(param).forEach((key) => {
    if (param[key] === null || param[key] === "" || param[key] === undefined) {
      delete param[key];
    }
  });

  return Object.keys(param)
    .map((key) => `${key}=${encodeURIComponent(param[key])}`)
    .join("&");
};
