import React, { useState } from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Button,
  Stack,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
  Tooltip,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { Visibility } from "@mui/icons-material";
import Loader from "../ui/Loader";

import {
  activeIndicateColor,
  inActiveIndicateColor,
  imageDefaultStyleContain,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
} from "../../theme/globalStyles";
import { customGreen } from "../../theme/variants";
import HtmlParser from "react-html-parser";
import { getProduct } from "../../api/product";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 30vw)",
  maxHeight: "calc(100% - 20vh)",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 10,
  borderRadius: "24px",
  "::-webkit-scrollbar": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: customGreen[100],
    borderRadius: "24px",
  },
};

export default function ViewProduct({ id }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchProduct = () => {
    setLoading(true);
    getProduct(id)
      .then((response) => {
        setLoading(false);
        setData(response.data.payload);
        console.log(response.data.payload);
      })
      .catch((error) => handleClose());
  };

  const handleOpen = () => {
    setOpen(true);
    fetchProduct();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sxTableCell = {
    py: 1,
    px: 2,
    fontSize: "15px",
    width: "100%",
  };

  return (
    <div>
      <Tooltip title="Product Details" placement="top" arrow>
        <IconButton
          color="secondary"
          sx={{ ...sxTableActionButtonBorder }}
          onClick={handleOpen}
        >
          <Visibility />
        </IconButton>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Box sx={{ mt: 5 }}>
                <Box sx={{ width: "300px", height: "300px", margin: "auto" }}>
                  <img
                    src={data?.image?.fileUrl ?? "/default-image.png"}
                    alt=""
                    style={{
                      ...imageDefaultStyleContain,
                      borderRadius: "24px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Box>

              <Typography mt={5} variant="h2" align="center">
                {data.name}
              </Typography>
              <Stack
                mt={5}
                gap={{ xs: 5, md: 10 }}
                justifyContent={{ xs: "start", md: "space-between" }}
                alignItems={{ xs: "start", md: "center" }}
                direction={{ xs: "column", md: "row" }}
              >
                <TableContainer
                  sx={{
                    height: { xs: "250px", lg: "100%" },
                    "&::-webkit-scrollbar": {
                      background: customGreen[100],
                      borderRadius: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: customGreen[200],
                      borderRadius: "5px",
                    },
                    mb: 3,
                  }}
                >
                  <Table
                    sx={{
                      ...sxFancyTableBorder,
                      minWidth: "280px",
                    }}
                  >
                    <TableBody sx={{ width: "100%" }}>
                      <TableRow sx={{ width: "100%" }}>
                        <TableCell sx={sxTableCell}>
                          <strong>Name:</strong>{" "}
                          {data?.productName ?? "Unavailable"}
                        </TableCell>
                      </TableRow>

                      <TableRow sx={{ width: "100%" }}>
                        <TableCell sx={sxTableCell}>
                          <strong>Price:</strong>{" "}
                          {`${data?.productPrice} $` ?? "Unavailable"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={sxTableCell}>
                          <strong>Description:</strong>{" "}
                          {data?.description ?? "Unavailable"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={sxTableCell}>
                          <strong>Specification:</strong>{" "}
                          {HtmlParser(data?.specification) ?? "Unavailable"}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={sxTableCell}>
                          <strong style={{ display: "block" }}>
                            Attributes:{" "}
                          </strong>
                          <Stack
                            direction={{ xs: "column", md: "row" }}
                            gap={5}
                            flexWrap="wrap"
                            my={3}
                          >
                            {data.productAttributes?.map((attr) => (
                              <Stack
                                key={attr.id}
                                direction="column"
                                sx={{
                                  p: 3,
                                  ...sxFancyTableBorder,
                                }}
                              >
                                <Box>
                                  <strong
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {attr.productAttributeName.toLowerCase()}:{" "}
                                  </strong>
                                  {attr.value}
                                </Box>

                                <Box>
                                  <strong>Incremented Price: </strong>
                                  {attr.priceIncrement} $
                                </Box>

                                <Stack
                                  direction="row"
                                  gap={1}
                                  alignItems="center"
                                >
                                  <strong>Stock: </strong>
                                  <div
                                    style={{
                                      height: "8px",
                                      width: "8px",
                                      borderRadius: "8px",
                                      backgroundColor: attr.stock
                                        ? activeIndicateColor
                                        : inActiveIndicateColor,
                                    }}
                                  ></div>
                                  {attr.stock ? "Yes" : "No"}
                                </Stack>
                              </Stack>
                            ))}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Stack direction="row" justifyContent={"end"}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleClose()}
                >
                  Close
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
