import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  createAddress,
  getAddressById,
  updateAddress,
} from "../../../api/eventAddress";
import EventAddressForm from "../../../components/form/EventAddressForm";
import useAuth from "../../../hooks/useAuth";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AddEventAddress = () => {
  // states
  const [eventAddress, setEventAddress] = useState({});

  // objects
  const navigate = useNavigate();
  const params = useParams();
  const eventAddressId = params?.id;
  const { user } = useAuth();

  // functions
  const fetchEventAddress = () => {
    if (eventAddressId) {
      getAddressById(eventAddressId).then((response) => {
        console.log("get eventAddress response: ", response.data.payload);
        setEventAddress(response.data.payload);
      });
    }
  };

  const submitEventAddress = (values) => {
    console.log("Values: ", values);

    const eventId = values.eventId.id;
    const data = {
      addressId: eventAddressId,
      addressLine1: values.addressLine1,
      addressLine2: "",
      addressType: "EVENT",
      country: values.country,
      eventId: eventId,
      lattitude: values.lattitude,
      longitude: values.longitude,
      postalCode: values.postalCode,
      state: values.state,
      town: values.town,
      userId: user.id,
    };

    if (eventAddressId) {
      updateAddress(data)
        .then((response) => {
          console.log("event address update response: ", response);
          return navigate("/event/address/all");
        })
        .catch((error) => console.log(`Error: ${error.response.data.message}`));
    } else {
      createAddress(data)
        .then((response) => {
          console.log("Event Address create response", response);
          return navigate("/event/address/all");
        })
        .catch((error) => console.log(`Error: ${error.response.data.message}`));
    }
  };

  useEffect(() => {
    fetchEventAddress();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Add Event Address" />
      <Typography variant="h3" gutterBottom display="inline">
        Add Event Address
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/event/all">
          Events
        </Link>

        <Link component={NavLink} to="/event/address/all">
          Event Address
        </Link>

        <Typography>Add</Typography>
      </Breadcrumbs>

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={8}>
          <Card p={8}>
            <EventAddressForm
              navigate={navigate}
              eventAddress={eventAddress}
              onSubmit={submitEventAddress}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddEventAddress;
