import axios from "../utils/axios";

// get all album
export const getAllAlbum = (filters) => {
  return axios.get(`api/albums/all?${filters}`);
};

// create new album
export const createAlbum = (data) => {
  return axios.post("api/albums/create", data);
};

// get a album
export const getAlbumById = (id) => {
  return axios.get(`api/albums/id/${id}`);
};

// update album
export const updateAlbum = (data) => {
  return axios.put("api/albums/update", data);
};

// delete album
export const deleteAlbum = (id) => {
  return axios.delete(`api/albums/delete/id/${id}`);
};
