import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Box,
} from "@mui/material";
import { spacing } from "@mui/system";

import SliderForm from "../../../components/form/SliderForm";
import { uploadFile } from "../../../api/file";
import { createSlider, getSliderById, updateSlider } from "../../../api/slider";
import ImageUpload from "../../../components/ui/ImageUploader";
import ProductSliderForm from "../../../components/form/ProductSliderForm";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AddProductSlider = () => {
  // states
  const [slider, setSlider] = useState({});
  const [image, setImage] = useState("/placeholder-image.jpg");
  const [imageId, setImageId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmittingSlider, setIsSubmittingSlider] = useState(false);

  // objects
  const navigate = useNavigate();
  const params = useParams();
  const walkthroughId = params?.id;

  // functions
  const fetchSlider = () => {
    if (walkthroughId) {
      getSliderById(walkthroughId).then((response) => {
        console.log("get slider response: ", response.data.payload);
        setSlider(response.data.payload);
        setImage(response.data.payload?.imageFile?.fileUrl);
        setImageId(response.data.payload?.imageFile?.id);
      });
    }
  };

  const submitSlider = (values) => {
    setIsSubmittingSlider(true);

    console.log("Values: ", values);

    if (values.image && !values.image.fileUrl) {
      const formData = new FormData();
      formData.append("file", values.image);
      formData.append("fileType ", "WALKTHROUGH");
      formData.append("fileUploadType", "WALKTHROUGH");

      uploadFile(formData)
        .then((response) => {
          const data = {
            walkthroughId: walkthroughId,
            dbFileId: response?.data?.payload.id,
            message: values.message,
            title: values.title,
            walkthroughType: "SHOP",
            active: values.active,
          };

          if (walkthroughId) {
            updateSlider(data)
              .then((response) => {
                return navigate("/product-slider/all");
              })
              .catch((error) => {
                console.log(error.response.data.message);
                setIsSubmittingSlider(false);
                setErrorMessage(
                  error.response.data.message ||
                    "Something went wrong, please try again later"
                );
              });
          } else {
            createSlider(data)
              .then((response) => {
                console.log("slider create response", response);
                return navigate("/product-slider/all");
              })
              .catch((error) => {
                console.log(error.response.data.message);
                setIsSubmittingSlider(false);
                setErrorMessage(
                  error.response.data.message ||
                    "Something went wrong, please try again later"
                );
              });
          }
        })
        .catch((error) => {
          setIsSubmittingSlider(false);
          setErrorMessage(
            error.response.data.message ||
              "Something went wrong, please try again later"
          );
        });
    } else {
      const data = {
        walkthroughId: walkthroughId,
        dbFileId: slider?.imageFile?.id,
        message: values.message,
        title: values.title,
        walkthroughType: "SHOP",
        active: values.active,
      };

      if (walkthroughId) {
        updateSlider(data)
          .then((response) => {
            return navigate("/product-slider/all");
          })
          .catch((error) => {
            setIsSubmittingSlider(false);
            console.log(error.response.data.message);
            setErrorMessage(
              error.response.data.message ||
                "Something went wrong, please try again later"
            );
          });
      } else {
        createSlider(data)
          .then((response) => {
            console.log("slider create response", response);
            return navigate("/product-slider/all");
          })
          .catch((error) => {
            setIsSubmittingSlider(false);
            console.log(error.response.data.message);
            setErrorMessage(
              error.response.data.message ||
                "Something went wrong, please try again later"
            );
          });
      }
    }
  };

  useEffect(() => {
    fetchSlider();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={walkthroughId ? "Update Slider" : "Add Slider"} />
      <Typography variant="h3" gutterBottom display="inline">
        {walkthroughId ? "Update Slider" : "Add Slider"}
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>

        <Link component={NavLink} to="/product-slider/all">
          Slider
        </Link>

        <Typography>{walkthroughId ? "Update" : "Add"}</Typography>
      </Breadcrumbs>

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} lg={10}>
          <Card p={8}>
            <ProductSliderForm
              errorMessage={errorMessage}
              navigate={navigate}
              slider={slider}
              onSubmit={submitSlider}
              isSubmittingSlider={isSubmittingSlider}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AddProductSlider;
