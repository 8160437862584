import { Box, TextField } from "@mui/material";
import React from "react";

const ImageFieldError = ({ errors, touched }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <TextField
        disabled
        error={Boolean(touched.image && errors.image)}
        helperText={touched.image && errors.image}
        sx={{
          textAlign: "center",
          padding: 0,
          ".MuiInputBase-input": {
            padding: 0,
          },
          ".MuiInputBase-root": {
            height: "1px",
            padding: 0,
            "&:hover": {
              border: "none !important",
            },
            "&:hover fieldset": {
              border: `none !important`,
            },
            "&.Mui-focused fieldset": {
              border: `none !important`,
            },
            fieldSet: {
              border: "none",
            },
          },
          ".MuiFormHelperText-root": {
            textAlign: "center",
          },
        }}
      />
    </Box>
  );
};

export default ImageFieldError;
