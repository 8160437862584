import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate, NavLink } from "react-router-dom";
import {
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  Autocomplete,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { spacing } from "@mui/system";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";
import { getAllAddress } from "../../../api/eventAddress";
import NotFound from "../../../components/ui/NotFound";
import ContentLoader from "../../../components/ui/ContentLoader";
import SliderTable from "../../../components/table/SliderTable";
import EventAddressTable from "../../../components/table/EventAddressTable";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const EventAddress = () => {
  // states
  const [allEventAddress, setAllEventAddress] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    postalCode: "",
    town: "",
    state: "",
    country: "",
  });
  const [filterBy, setFilterBy] = useState("country");

  // objects
  const navigate = useNavigate();

  const filters = [
    {
      label: "Postal Code",
      value: "postalCode",
    },
    {
      label: "Town",
      value: "town",
    },
    {
      label: "State",
      value: "state",
    },
    {
      label: "Country",
      value: "country",
    },
  ];

  // functions
  const fetchAllEventAddress = () => {
    setLoading(true);
    getAllAddress(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        postalCode: search.postalCode,
        town: search.town,
        state: search.state,
        country: search.country,
      })
    ).then((res) => {
      console.log(res.data.payload.content);
      const data = res.data.payload;
      setAllEventAddress(data.content);
      setCount(data.totalElements);
      setRowsPerPage(data.pageable.pageSize);
      setCurrentPage(data.pageable.pageNumber);
      setLoading(false);
    });
  };

  // searching
  const handleSearch = (e) => {
    console.log("handle search clicked");
    let value = e.target.value;
    setSearch({ [filterBy]: value });
  };

  // filtering
  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // use-effect
  useEffect(() => {
    fetchAllEventAddress();
  }, [currentPage, rowsPerPage, search]);

  return (
    <React.Fragment>
      <Helmet title="Event Address - List" />
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Event Address List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Typography component={NavLink} to="/event/all">
              Event
            </Typography>

            <Typography>Event Address</Typography>

            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            onClick={() => navigate("/event/address/add")}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Event Address
          </Button>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              <TextField
                size="small"
                label={"Search Here..."}
                variant="outlined"
                onChange={(e) => debounce(() => handleSearch(e), 800)}
              />
            </Stack>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allEventAddress.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <EventAddressTable allEventAddress={allEventAddress} />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EventAddress;
