import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";
import { rgba } from "polished";

import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  getActiveUsersMonthly,
  getRetiredUsersMonthly,
} from "../../../api/dashboard";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 340px;
  width: 100%;
`;

const BarChart = ({ theme }) => {
  const [activeUser, setActiveUser] = useState();
  const [retiredUser, setRetiredUser] = useState();

  const fetchAllActiveUser = () => {
    getActiveUsersMonthly()
      .then((res) => {
        setActiveUser(res.data.payload);
      })
      .catch((error) => console.log("Active User Error: ", error));
  };

  const fetchAllRetiredUser = () => {
    getRetiredUsersMonthly()
      .then((res) => {
        setRetiredUser(res.data.payload);
      })
      .catch((error) => console.log("Retired User Error: ", error));
  };

  const firstDatasetColor = theme.palette.secondary.main;
  const secondDatasetColor = rgba(theme.palette.secondary.main, 0.33);

  const data = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Active User",
        backgroundColor: firstDatasetColor,
        borderColor: firstDatasetColor,
        hoverBackgroundColor: firstDatasetColor,
        hoverBorderColor: firstDatasetColor,
        // data: [54, 67, 41, 55, 62, 45, 55, 73, 60, 76, 48, 79],
        data: activeUser,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
      },
      {
        label: "Retired User",
        backgroundColor: secondDatasetColor,
        borderColor: secondDatasetColor,
        hoverBackgroundColor: secondDatasetColor,
        hoverBorderColor: secondDatasetColor,
        data: retiredUser,
        barPercentage: 0.5,
        categoryPercentage: 0.5,
        borderRadius: 6,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        stacked: true,
        ticks: {
          stepSize: 20,
          fontColor: theme.palette.text.secondary,
        },
      },

      x: {
        stacked: true,
        grid: {
          color: "transparent",
        },
        ticks: {
          fontColor: theme.palette.text.secondary,
        },
      },
    },
  };

  useEffect(() => {
    fetchAllActiveUser();
    fetchAllRetiredUser();
  }, []);

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Active / Inactive Users"
      />

      <CardContent>
        <ChartWrapper>
          <Chart type="bar" data={data} options={options} />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(BarChart);
