import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import {
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  Link,
  Modal,
  CircularProgress,
  TextField,
  Stack,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, spacing } from "@mui/system";
import params from "../../../utils/params";
import { getAllMemory, deleteMemory, createMemory } from "../../../api/memory";
import NotFound from "../../../components/ui/NotFound";
import ContentLoader from "../../../components/ui/ContentLoader";
import MemoryTable from "../../../components/table/MemoryTable";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import AlbumMemoryTable from "../../../components/table/AlbumMemoryTable";
import ClearIcon from "@mui/icons-material/Clear";
import MemoryForm from "../../../components/form/MemoryForm";
import { Memory } from "@mui/icons-material";
import * as Yup from "yup";
import { Formik } from "formik";
import ImageUpload from "../../../components/ui/ImageUpload";
import ImageFieldError from "../../../components/ui/ImageFieldError";
import { imageDefaultStyleContain } from "../../../theme/globalStyles";
import { uploadFile } from "../../../api/file";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const AlbumMemory = (props) => {
  // states
  const [allMemory, setAllMemory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [isDeleteMemory, setIsDeleteMemory] = useState(false);
  const [isSubmittingMemory, setIsSubmittingMemory] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // objects
  const navigate = useNavigate();
  const param = useParams();
  const albumId = param?.id;

  // functions
  // fetch Memory
  const fetchAllMemory = () => {
    setLoading(true);
    getAllMemory(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        sortBy: "lastModifiedDate",
        ascOrDesc: "desc",
        albumId: albumId,
      })
    ).then((res) => {
      console.log(res.data.payload.content);
      const data = res.data.payload;
      setAllMemory(data.content);
      setCount(data.totalElements);
      setRowsPerPage(data.pageable.pageSize);
      setCurrentPage(data.pageable.pageNumber);
      setLoading(false);
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteAlbum = () => {
    setIsDeleteMemory(true);
    deleteMemory(deleteId)
      .then((response) => {
        setIsDeleteMemory(false);
        console.log("Deleted successfully");
        setDeleteId(null);
        setShowDelete(false);
        fetchAllMemory();
      })
      .catch((error) => {
        setIsDeleteMemory(false);
        console.log(error.response.data.message);
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later"
        );
      });
  };

  // validation
  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is Required"),
    // albumId: Yup.object().nullable().required("Album is required"),
  });

  // submit

  // styles
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: `calc(100% - 20%)`,
      md: `calc(100% - 50%)`,
      xl: `calc(100% - 60%)`,
    },
    // height: `calc(100% - 20%)`,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 5,
    borderRadius: "32px",
    overflow: "auto",
  };

  // use-effect
  useEffect(() => {
    fetchAllMemory();
  }, [currentPage, rowsPerPage]);

  return (
    <React.Fragment>
      <Helmet title="Album Memory - List" />

      {/* delete dialog */}
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Memory?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isDeleteMemory}
            onClick={submitDeleteAlbum}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Albums Memory List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Link component={NavLink} to="/album">
              Album
            </Link>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <Button
            onClick={() => handleOpen()}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Multiple Memory
          </Button>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allMemory.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <Stack justifyContent={"center"} direction={"row"}>
                      {errorMessage && (
                        <ErrorAlert title={errorMessage} mb={3} />
                      )}
                    </Stack>

                    <AlbumMemoryTable
                      deleteMemory={showDeleteModal}
                      allMemory={allMemory}
                    />

                    <TablePagination
                      rowsPerPageOptions={[100, 150, 200]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>

      {/* add multiple memory modal */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* form */}
          <Formik
            initialValues={{ image: null }}
            validationSchema={validationSchema}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
              console.log("values: ", values.image);
              setIsSubmittingMemory(true);
              for (let i = 0; i < values.image.length; i++) {
                try {
                  console.log(values.image.length);
                  const formData = new FormData();
                  formData.append("file", values.image[i]);
                  formData.append("fileType ", "MEMORY_PHOTO");
                  formData.append("fileUploadType", "MEMORY_PHOTO");

                  let reponse = await uploadFile(formData);

                  try {
                    await createMemory({
                      albumId: albumId,
                      dbFileId: reponse.data.payload.id,
                    });
                  } catch (error) {
                    setErrorMessage(
                      error.response.data.message ||
                        "Something went wrong, please try again later"
                    );
                  }
                  setStatus({ sent: true });
                  setSubmitting(false);
                } catch (error) {
                  setStatus({ sent: false });
                  setErrors({ submit: error.message });
                  setSubmitting(false);
                  setErrorMessage(
                    error.response.data.message ||
                      "Something went wrong, please try again later"
                  );
                }
              }
              setIsSubmittingMemory(false);
              fetchAllMemory();
              handleClose();
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              status,
              resetForm,
              setFieldValue,
            }) => (
              <Card
                sx={{
                  p: 5,
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {isSubmitting ? (
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      {/* alert */}
                      <Grid item xs={12}>
                        {errorMessage && (
                          <ErrorAlert title={errorMessage} mb={3} />
                        )}
                      </Grid>

                      {/* image upload */}
                      <Grid item xs={12} mb={3}>
                        <ImageUpload
                          name="image"
                          multiple={true}
                          hideDeleteBtn={hideDeleteBtn}
                          onRemove={() => {
                            setFieldValue("image", null);
                            setHideDeleteBtn(true);
                          }}
                          onChange={(event) => {
                            setFieldValue("image", [...event.target.files]);
                            setHideDeleteBtn(false);
                          }}
                          imgSrc={
                            values?.image?.fileUrl
                              ? values?.image[0]?.fileUrl
                              : values?.image
                              ? URL.createObjectURL(values?.image[0])
                              : "/upload-placeholder.jpg"
                          }
                        />
                        <Stack
                          mt={5}
                          direction={"row"}
                          sx={{ width: "100%", height: "100px" }}
                        >
                          {values?.image?.map((item, index) => (
                            <img
                              key={index}
                              src={URL.createObjectURL(item)}
                              alt={item.name}
                              style={imageDefaultStyleContain}
                            />
                          ))}
                        </Stack>
                      </Grid>
                    </Grid>
                    {/* action buttons */}
                    <Grid item xs={12}>
                      <Stack justifyContent="center" direction="row" gap={4}>
                        <Button variant="outlined" onClick={handleClose} mt={3}>
                          Cancel
                        </Button>

                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          mt={3}
                          disabled={isSubmittingMemory}
                        >
                          Upload Memories
                        </Button>
                      </Stack>
                    </Grid>
                  </form>
                )}
              </Card>
            )}
          </Formik>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default AlbumMemory;
