import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";

import { Edit, Delete } from "@mui/icons-material";
import {
  blueBorder,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
} from "../../theme/globalStyles";

export default function RoleTable(props) {
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
        }}
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell>Type</TableCell>

            <TableCell sx={{ whiteSpace: "nowrap" }}>Role Name</TableCell>

            <TableCell>Privilege</TableCell>

            <TableCell>Description</TableCell>

            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {props.rows.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.roleType}</TableCell>

              <TableCell sx={{ whiteSpace: "nowrap" }}>
                {row.roleName}
              </TableCell>

              <TableCell>
                <Box sx={{ display: "flex", gap: 3, flexWrap: "wrap" }}>
                  {row.privileges?.map((privilege) => (
                    <Box
                      key={privilege.id}
                      sx={{
                        padding: "4px 8px",
                        ...blueBorder,
                        borderRadius: "8px",
                        textTransform: "capitalize",
                      }}
                    >
                      {privilege?.name.replace("_", " ").toLowerCase()}
                    </Box>
                  ))}
                </Box>
              </TableCell>

              <TableCell>{row?.description}</TableCell>

              <TableCell>
                <div
                  style={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Edit Role" arrow>
                    <IconButton
                      color="primary"
                      sx={{ ...sxTableActionButtonBorder }}
                      onClick={() => props.onEditHandler(row.id)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete Role" arrow>
                    <IconButton
                      color="error"
                      sx={{ ...sxTableActionButtonBorderRed }}
                      onClick={() => props.deleteEvent(row.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
