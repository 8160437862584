import axios from "../utils/axios";

// get all role by type
export const getRoleType = (roleType) => {
  const roleTypeUpperCase = roleType.toUpperCase();
  return axios.get(`api/role?roleType=${roleTypeUpperCase}`);
};

// crate a new role
export const createRole = (data) => {
  return axios.post("api/role", data);
};

// get all roles
export const getAllRoles = (filters) => {
  return axios.get(`api/role/all?${filters}`);
};

// get specific role data
export const getRolebyId = (id) => {
  return axios.get(`api/role/${id}`);
};

// edit a role
export const updateRole = (data) => {
  return axios.put("api/role", data);
};

// delete a role
export const deleteRole = (id) => {
  return axios.delete(`api/role/${id}`);
};
