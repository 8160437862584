import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";
import { MoreVertical } from "react-feather";

import { orange, green, red, purple } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getOrderWeekly } from "../../../api/dashboard";
import { sxFancyTableBorder } from "../../../theme/globalStyles";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 245px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

const TableRow = styled(MuiTableRow)`
  height: 55px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const DoughnutChart = ({ theme }) => {
  // state
  const [weeklyOrder, setWeeklyOrder] = useState([]);

  let initialValue = 0;
  let weeklyOrderData = [];

  // function
  const fetchOrderWeekly = () => {
    getOrderWeekly()
      .then((res) => {
        for (let i = 0; i < res.data.payload.length; i++) {
          weeklyOrderData.push(res.data.payload[i].count);
        }
        setWeeklyOrder(weeklyOrderData);
      })
      .catch((error) => console.log("Weekly Order Error: ", error));
  };

  const data = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4", "Week 5"],
    datasets: [
      {
        // data: [1, 0, 0, 10, 0],
        data: weeklyOrder,
        backgroundColor: [
          theme.palette.primary.main,
          red[500],
          orange[500],
          green[500],
          purple[500],
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  useEffect(() => {
    fetchOrderWeekly();
  }, []);

  return (
    <Card mb={6}>
      <CardHeader
        action={
          <IconButton aria-label="settings" size="large">
            <MoreVertical />
          </IconButton>
        }
        title="Weekly sales"
      />

      <CardContent>
        <ChartWrapper>
          <DoughnutInner>
            <Typography variant="h4">
              {weeklyOrder.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                initialValue
              )}
            </Typography>
            <Typography variant="caption">Total Sell</Typography>
          </DoughnutInner>
          <Chart type="doughnut" data={data} options={options} />
        </ChartWrapper>
        <Table sx={{ ...sxFancyTableBorder, mt: 5 }}>
          <TableHead>
            <TableRow>
              <TableCell align="right">Weeks</TableCell>
              <TableCell align="right">1</TableCell>
              <TableCell align="right">2</TableCell>
              <TableCell align="right">3</TableCell>
              <TableCell align="right">4</TableCell>
              <TableCell align="right">5</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Sell
              </TableCell>
              {weeklyOrder.map((item, index) => (
                <TableCell key={index} align="right">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
