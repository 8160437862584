import { React, useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider,
  Typography,
  TextField,
  Breadcrumbs,
  Button,
  Stack,
  Card,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Autocomplete,
  Link,
} from "@mui/material";

import { createRole } from "../../api/role";
import { getAllPriviledge } from "../../api/priviledge";
import * as Yup from "yup";
import { ErrorMessage, Formik } from "formik";
import { sxBreadcrumbsStyle } from "../../theme/globalStyles";
import ErrorAlert from "../../components/ui/ErrorAlert";

const RoleCreate = () => {
  const navigate = useNavigate();

  const [allPriviledge, setAllPrivildge] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const initialValues = {
    roleName: "",
    description: "",
    roleType: "ADMIN",
    selectPrivileges: [],
  };

  const validationSchema = Yup.object().shape({
    roleName: Yup.string().required("Required"),
    roleType: Yup.string().required("Required"),
    selectPrivileges: Yup.array()
      .min(1, "Privileges are required")
      .of(Yup.object()),
  });

  const onSubmitHandler = (values) => {
    let selectedPrivileges = [];

    values.selectPrivileges.map((item) => selectedPrivileges.push(item.id));

    const data = {
      roleName: values.roleName,
      roleType: values.roleType,
      previlegeId: selectedPrivileges,
      description: values.description,
    };

    createRole(data)
      .then((res) => {
        return navigate("/role/all", { replace: true });
      })
      .catch((error) => {
        console.log("error: ", error);
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later"
        );
      });
  };

  useEffect(() => {
    getAllPriviledge()
      .then((res) => {
        const data = res.data.payload;
        setAllPrivildge(data);
      })
      .catch((error) => {
        console.log("error: ", error);
      });
  }, []);

  return (
    <div>
      <Helmet title="Create Role" />

      <Typography variant="h3" gutterBottom display="flex" alignItems="center">
        Add New Role
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2} mb={3}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="/role/all">
          Roles
        </Link>
        <Typography>Create New Role</Typography>
      </Breadcrumbs>

      <Divider />
      <Grid container mt={5} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Card sx={{ p: 10 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmitHandler}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Stack direction="column" gap={5}>
                    <Typography variant="h2" align={"center"}>
                      Create New Role
                    </Typography>
                    <Divider />
                    {errorMessage && <ErrorAlert title={errorMessage} />}
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      name="roleName"
                      error={Boolean(touched.roleName && errors.roleName)}
                      helperText={touched.roleName && errors.roleName}
                      fullWidth
                      size={"small"}
                      placeholder="Enter Your Name"
                      variant="outlined"
                      value={values.roleName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Role Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="roleType"
                        label="Select Role Type"
                        error={Boolean(touched.roleType && errors.roleType)}
                        value={values.roleType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      >
                        <MenuItem value="ADMIN">Admin</MenuItem>
                      </Select>
                    </FormControl>

                    <Autocomplete
                      multiple
                      options={allPriviledge}
                      getOptionLabel={(option) => option.name}
                      onOpen={handleBlur}
                      value={values.selectPrivileges}
                      filterSelectedOptions
                      onChange={(event, value) =>
                        setFieldValue("selectPrivileges", value)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="selectPrivileges"
                          error={Boolean(
                            touched.selectPrivileges && errors.selectPrivileges
                          )}
                          helperText={
                            touched.selectPrivileges && errors.selectPrivileges
                          }
                          label="Select Priviledge"
                          placeholder="Select Priviledge"
                        />
                      )}
                    />

                    <TextField
                      id="outlined-basic"
                      label="Description"
                      name="description"
                      fullWidth
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      size={"small"}
                      placeholder="Enter Role Description"
                      variant="outlined"
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <Stack direction={{ xs: "column", sm: "row" }} gap={3}>
                      <Button
                        variant="outlined"
                        onClick={() => navigate("/role/all")}
                        sx={{ width: { xs: "100%", sm: "50%" } }}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ width: { xs: "100%", sm: "50%" } }}
                      >
                        Create New Role
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default RoleCreate;
