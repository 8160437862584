import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  Autocomplete,
  Link,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { spacing } from "@mui/system";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";
import { deleteSlider, getAllSlider } from "../../../api/slider";
import NotFound from "../../../components/ui/NotFound";
import ContentLoader from "../../../components/ui/ContentLoader";
import SliderTable from "../../../components/table/SliderTable";
import ErrorAlert from "../../../components/ui/ErrorAlert";
import ProductSliderTable from "../../../components/table/ProductSliderTable";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ProductSliders = () => {
  // states
  const [allSlider, setAllSlider] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleteSlider, setIsDeleteSlider] = useState(false);
  const [search, setSearch] = useState({
    title: "",
    message: "",
    active: "",
  });
  const [filterBy, setFilterBy] = useState("title");
  const [errorMessage, setErrorMessage] = useState("");

  // objects
  const navigate = useNavigate();

  const filters = [
    {
      label: "Slider Title",
      value: "title",
    },
    {
      label: "Message",
      value: "message",
    },
    {
      label: "Status",
      value: "active",
    },
  ];

  const statusOption = [
    {
      title: "Active",
      value: "true",
    },
    {
      title: "Inactive",
      value: "false",
    },
  ];

  // functions
  const fetchAllSlider = () => {
    setLoading(true);
    getAllSlider(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        sortBy: "lastModifiedDate",
        ascOrDesc: "desc",
        title: search.title,
        message: search.message,
        active: search.active,
        walkthroughType: "SHOP",
      })
    ).then((res) => {
      console.log(res.data.payload.content);
      const data = res.data.payload;
      setAllSlider(data.content);
      setCount(data.totalElements);
      setRowsPerPage(data.pageable.pageSize);
      setCurrentPage(data.pageable.pageNumber);
      setLoading(false);
    });
  };

  // searching
  const handleSearch = (e) => {
    console.log("handle search clicked");
    let value;
    if (filterBy === "active") {
      value = e.value;
    } else {
      value = e.target.value;
    }
    setSearch({ [filterBy]: value });
  };

  // filtering
  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };
  const showDeleteModal = (id) => {
    console.log("delete id: ", id);
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteSlider = () => {
    setIsDeleteSlider(true);
    deleteSlider(deleteId)
      .then((res) => {
        setIsDeleteSlider(false);
        console.log("Admin Data Deleted!");
        setDeleteId(null);
        setShowDelete(false);
        fetchAllSlider();
      })
      .catch((error) => {
        setIsDeleteSlider(false);
        console.log(error.response.data.message);
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later"
        );
      });
  };

  // use-effect
  useEffect(() => {
    fetchAllSlider();
  }, [currentPage, rowsPerPage, search]);

  return (
    <React.Fragment>
      <Helmet title="Slider - List" />

      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Product Slider?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            disabled={isDeleteSlider}
            onClick={submitDeleteSlider}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Product Slider List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Typography>Slider List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            onClick={() => navigate("/product-slider/add")}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add Slider
          </Button>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              {filterBy === "active" ? (
                <Autocomplete
                  sx={{ width: 200 }}
                  id="tags-outlined"
                  options={statusOption}
                  value={null}
                  getOptionLabel={(option) => option.title}
                  onChange={(event, value) => handleSearch(value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="search"
                      label="Select Active Status"
                      my={2}
                      size="small"
                    />
                  )}
                />
              ) : (
                <TextField
                  size="small"
                  label={"Search Here..."}
                  variant="outlined"
                  onChange={(e) => debounce(() => handleSearch(e), 800)}
                />
              )}
            </Stack>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allSlider.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <ProductSliderTable
                      allSlider={allSlider}
                      deleteSlider={showDeleteModal}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProductSliders;
