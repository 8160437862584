import axios from "../utils/axios";

// create admin
export const createAdmin = (data, config) => {
  return axios.post("api/admin-management/create-admin", data, config);
};

// get specific admin data
export const getAdminInfo = () => {
  return axios.get("api/admin-management/get-admin");
};

// get all admin data
export const getAllAdmin = (filters) => {
  return axios.get(`api/admin-management/all?${filters}`);
};

// update admin name and image
export const updateAdmin = (data) => {
  return axios.put("api/admin-management/update-admin", data);
};

// delete admin from list
export const deleteAdmin = (id) => {
  return axios.delete(`api/admin-management/delete/id/${id}`);
};
