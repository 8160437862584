import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  open: false,
  message: "",
  type: "success",
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    message: (state, action) => {
      state.open = action.payload.open;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    clearMessage: (state) => {
      state.open = false;
      state.message = "";
      //   state.type = "";
    },
  },
});

export const { message, clearMessage } = notificationSlice.actions;

export default notificationSlice.reducer;
