import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Grid,
  Button,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  Link,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { spacing } from "@mui/system";
import params from "../../../utils/params";

import NotFound from "../../../components/ui/NotFound";
import ContentLoader from "../../../components/ui/ContentLoader";
import {
  deleteNotification,
  getAllNotification,
} from "../../../api/notification";
import NotificationTable from "../../../components/table/NotificationTable";
import { AddCircleOutline } from "@mui/icons-material";
import ErrorAlert from "../../../components/ui/ErrorAlert";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Notifications = () => {
  // states
  const [allNotification, setAllNotification] = useState([]);
  const [loading, setLoading] = useState(true);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // objects
  const navigate = useNavigate();

  // functions
  // fetch Notifications
  const fetchNotifications = () => {
    setLoading(true);
    getAllNotification(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        sortBy: "lastModifiedDate",
        ascOrDesc: "desc",
      })
    ).then((res) => {
      setAllNotification(res.data.payload.content);
      setCount(res.data.payload.totalElements);
      setRowsPerPage(res.data.payload.pageable.pageSize);
      setCurrentPage(res.data.payload.pageable.pageNumber);
      setLoading(false);
    });
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    handleChangePage(0);
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteSubscription = async () => {
    setIsDeleting(true);

    try {
      await deleteNotification(deleteId);

      setIsDeleting(false);
      setDeleteId(null);
      setShowDelete(false);
      fetchNotifications();
    } catch (error) {
      setIsDeleting(false);
      console.log(error.response.data.message);
      setErrorMessage(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  // use-effect
  useEffect(() => {
    fetchNotifications();
  }, [currentPage, rowsPerPage]);

  return (
    <React.Fragment>
      <Helmet title="Notification - List" />

      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Notification?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            color="error"
            disabled={isDeleting}
            onClick={submitDeleteSubscription}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Notification List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Typography>Notification List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            onClick={() => navigate("/notification/send")}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            <AddCircleOutline sx={{ mr: 1 }} />
            Create Notification
          </Button>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allNotification.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <NotificationTable
                      allNotification={allNotification}
                      deleteNotification={showDeleteModal}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Notifications;
