import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Tooltip,
  Modal,
  Typography,
  Stack,
  Button,
  TextField,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import {
  sxFancyTableBorder,
  imageDefaultStyleCover,
  sxTableHeadBG,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
} from "../../theme/globalStyles";
import ViewProduct from "../modals/ViewProuduct";
import SpecificProductAttribute from "../../pages/pages/Product/SpecificProductAttribute";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "calc(100% - 50%)",
    md: "calc(100% - 70%)",
    xl: "calc(100% - 80%)",
  },
  bgcolor: "background.paper",
  borderRadius: "25px",
  boxShadow: 24,
  p: 5,
};

export default function ProductTable(props) {
  const [open, setOpen] = React.useState(false);
  const [startDate, setStartDate] = React.useState(0);
  const [endDate, setEndDate] = React.useState(0);
  const [uuid, setUuid] = React.useState(null);
  const handleOpen = (uuid) => {
    setUuid(uuid);
    setOpen(true);
  };
  const handleClose = () => {
    setUuid(null);
    setOpen(false);
  };
  const [isDownloading, setIsDownloading] = React.useState(false);

  const navigate = useNavigate();

  const downloadTotalSoldReport = () => {
    setIsDownloading(true);

    if (startDate > 0 && endDate > 0) {
      fetch(
        `${
          process.env.REACT_APP_API_BASE_URL
        }api/order/item/${uuid}/report/generate?startDate=${parseInt(
          startDate
        )}&endDate=${parseInt(endDate)}`,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((response) => {
          response.blob().then((blob) => {
            setIsDownloading(false);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Sold Item Report.xlsx";
            link.click();
          });
        })
        .catch((error) => {
          if (error.message) {
            console.log("Error: ", error.message);
            setIsDownloading(false);
          } else {
            console.log("Error: Someting went wrong");
            setIsDownloading(false);
          }
        });
    } else {
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}api/order/item/${uuid}/report/generate`,
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      )
        .then((response) => {
          response.blob().then((blob) => {
            setIsDownloading(false);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "Sold Item Report.xlsx";
            link.click();
          });
        })
        .catch((error) => {
          if (error.message) {
            console.log("Error: ", error.message);
            setIsDownloading(false);
          } else {
            console.log("Error: Someting went wrong");
            setIsDownloading(false);
          }
        });
    }
  };

  const setStartDateHandler = (e) => {
    console.log("start date", e.timeStamp);
    setStartDate(e.timeStamp);
  };

  const setEndDateHandler = (e) => {
    console.log("end date", e.timeStamp);
    setEndDate(e.timeStamp);
  };

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell align="left">Image</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="center">Price</TableCell>
            <TableCell align="left">Description</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows?.map((row, index) => (
            <React.Fragment key={row.id}>
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  <Box
                    sx={{
                      height: "100px",
                      width: "150px",
                      borderRadius: "8px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={row?.image?.fileUrl ?? "/default-image.png"}
                      alt={row?.image?.fileName ?? row?.name}
                      style={{
                        ...imageDefaultStyleCover,
                        // objectFit: "contain",
                        borderRadius: "8px",
                      }}
                    />
                  </Box>
                </TableCell>
                <TableCell align="left">{row.productName}</TableCell>
                <TableCell align="right" sx={{ whiteSpace: "nowrap" }}>
                  {row.productPrice} $
                </TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell align="left">
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      justifyContent: "center",
                    }}
                  >
                    <ViewProduct id={row.id} />

                    <Tooltip title="Edit Product" placement="top" arrow>
                      <IconButton
                        color="primary"
                        sx={{ ...sxTableActionButtonBorder }}
                        onClick={() => navigate(`/product/edit/${row.id}`)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete Product" placement="top" arrow>
                      <IconButton
                        color="error"
                        sx={{ ...sxTableActionButtonBorderRed }}
                        onClick={() => props.deletePost(row.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Product Attribute Details" arrow>
                      <IconButton
                        color="primary"
                        sx={{ ...sxTableActionButtonBorder }}
                        onClick={() => navigate(`/product/${row.id}/attribute`)}
                      >
                        <FormatListBulletedIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Total Sold Product Report" arrow>
                      <IconButton
                        color="primary"
                        sx={{ ...sxTableActionButtonBorder }}
                        onClick={() => handleOpen(row.productUuid)}
                      >
                        <DownloadForOfflineIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>

              <Modal
                open={open}
                onClose={handleClose}
                sx={{
                  ".MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Box sx={style}>
                  <Box>
                    <Typography variant="h3" mb={5} textAlign={"center"}>
                      You can pick a date range for date wise report:{" "}
                    </Typography>

                    <Stack
                      direction={"column"}
                      gap={3}
                      justifyContent="space-evenly"
                      mb={5}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        label="Select Start Date"
                        size="small"
                        // value={startDate}
                        fullWidth
                        onChange={(e) => setStartDateHandler(e)}
                        variant="outlined"
                      />

                      <TextField
                        InputLabelProps={{ shrink: true }}
                        type="date"
                        label="Select End Date"
                        size="small"
                        // value={endDate}
                        fullWidth
                        onChange={setEndDateHandler}
                        variant="outlined"
                      />
                    </Stack>
                  </Box>

                  <Stack direction={"row"} justifyContent="end" gap={3}>
                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>

                    <Button
                      variant="outlined"
                      disabled={isDownloading}
                      onClick={() => downloadTotalSoldReport()}
                    >
                      Download
                    </Button>
                  </Stack>
                </Box>
              </Modal>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
