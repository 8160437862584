import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Divider,
  Typography,
  TextField,
  Breadcrumbs,
  Button,
  Stack,
  Card,
  Link,
  Box,
  Autocomplete,
  Alert,
} from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { createAdmin } from "../../api/admin";
import { getAllRoles } from "../../api/role";
import { useNavigate, NavLink } from "react-router-dom";

import * as Yup from "yup";
import { Formik } from "formik";
import ErrorAlert from "../../components/ui/ErrorAlert";

const AdminCreate = () => {
  const { user } = useAuth();
  const [allRole, setAllRole] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let navigate = useNavigate();

  const fetchAllRoles = async () => {
    try {
      let response = await getAllRoles();
      const data = response?.data?.payload?.content ?? {};
      setAllRole(data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  // validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required("Email Address is Required"),
    firstName: Yup.string().required("First Name is Required"),
    roleId: Yup.object().nullable().required("Role Type is required"),
  });

  // initial values
  const initialValues = {
    email: "",
    firstName: "",
    middleName: "",
    lastName: "",
    roleId: null,
  };

  // submit form
  const onSubmitHandler = async (values) => {
    setIsSubmitting(true);

    const roleId = values.roleId.id;

    const data = {
      email: values.email,
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      roleId: roleId,
    };

    // create new admin
    try {
      await createAdmin(data);

      values.email = "";
      values.firstName = "";
      values.middleName = "";
      values.lastName = "";
      values.roleId = "";
      setIsSubmitting(true);

      return navigate("/admin/all");
    } catch (error) {
      console.log("Error: ", error);
      setErrorMessage(
        error.response.data.message ||
          "Something went wrong, please try again later"
      );
    }
  };

  useEffect(() => {
    fetchAllRoles();
  }, []);

  return (
    <div>
      <Helmet title="Create Admin" />

      <Stack
        justifyContent={{ xs: "start", sm: "space-between" }}
        alignItems={"end"}
        direction={{ xs: "column", sm: "row" }}
      >
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            display="flex"
            alignItems="center"
          >
            <span>{`${user?.firstName} ${user?.lastName ?? ""}`}</span>
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Link component={NavLink} to="/admin/all">
              Admins
            </Link>

            <Typography>Create Admin</Typography>
          </Breadcrumbs>
        </Box>

        <Box>
          <Button
            variant="outlined"
            onClick={() => {
              navigate("/admin/all");
            }}
          >
            Go Back To Admins
          </Button>
        </Box>
      </Stack>

      <Divider sx={{ my: 3 }} />
      <Grid container mt={5} justifyContent={"center"} alignItems={"center"}>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Card sx={{ p: 10 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={onSubmitHandler}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                touched,
                values,
                status,
              }) => (
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                  <Stack direction="column" gap={5}>
                    <Typography variant="h2" align={"center"}>
                      Create New Admin
                    </Typography>
                    <Divider />
                    {errorMessage && <ErrorAlert title={errorMessage} />}

                    {/* first name */}
                    <TextField
                      id="outlined-basic"
                      name="firstName"
                      label="First Name"
                      size={"small"}
                      placeholder="Enter Your First Name"
                      variant="outlined"
                      value={values.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    />

                    {/* middle name */}
                    <TextField
                      id="outlined-basic"
                      name="middleName"
                      label="Middle Name"
                      size={"small"}
                      placeholder="Enter Your Middle Name"
                      variant="outlined"
                      value={values.middleName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.middleName && errors.middleName)}
                      helperText={touched.middleName && errors.middleName}
                    />

                    {/* last name */}
                    <TextField
                      id="outlined-basic"
                      name="lastName"
                      label="Last Name"
                      size={"small"}
                      placeholder="Enter Your Last Name"
                      variant="outlined"
                      value={values.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.lastName && errors.lastName)}
                      helperText={touched.lastName && errors.lastName}
                    />

                    {/* email */}
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      name="email"
                      size={"small"}
                      placeholder="Enter Your Email"
                      variant="outlined"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />

                    {/* roles */}
                    <Autocomplete
                      onOpen={handleBlur}
                      value={values.roleId}
                      onChange={(event, value) =>
                        setFieldValue("roleId", value)
                      }
                      options={allRole}
                      getOptionLabel={(option) => option.roleName || ""}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.roleName}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select Admin Roles"
                          placeholder="Select Admin Roles"
                          error={Boolean(touched.roleId && errors.roleId)}
                          helperText={touched.roleId && errors.roleId}
                          my={2}
                        />
                      )}
                    />

                    <Link
                      component={NavLink}
                      to="/role/create"
                      textAlign={"end"}
                      fontSize={16}
                    >
                      Create New Privilege
                    </Link>

                    <Stack direction={{ xs: "column", sm: "row" }} gap={3}>
                      <Button
                        variant="outlined"
                        sx={{ width: { xs: "100%", sm: "50%" } }}
                        onClick={() => navigate("/admin/all")}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ width: { xs: "100%", sm: "50%" } }}
                      >
                        Create New Admin
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminCreate;
