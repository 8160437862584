import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  Card,
  Divider,
  Typography,
  Breadcrumbs,
  Stack,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  TextField,
  Link,
} from "@mui/material";
import { getAllAdmin, deleteAdmin } from "../../api/admin";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Visibility, Edit } from "@mui/icons-material";
import { useNavigate, NavLink } from "react-router-dom";
import ContentLoader from "../../components/ui/ContentLoader";
import NotFound from "../../components/ui/NotFound";
import params from "../../utils/params";
import { debounce } from "../../utils/debounce";
import AdminTable from "../../components/table/AdminTable";
import ErrorAlert from "../../components/ui/ErrorAlert";

const AdminAll = () => {
  // states
  const [allAdminData, setAllAdminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
  });
  const [filterBy, setFilterBy] = useState("firstName");

  // objects
  const navigate = useNavigate();

  const filters = [
    {
      label: "First Name",
      value: "firstName",
    },
    {
      label: "Middle Name",
      value: "middleName",
    },
    {
      label: "Last Name",
      value: "lastName",
    },
    {
      label: "Admin Email",
      value: "email",
    },
  ];

  // functions
  const getAdminList = () => {
    setLoading(true);
    getAllAdmin(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        sortBy: "lastModifiedDate",
        ascOrDesc: "desc",
        firstName: search.firstName,
        middleName: search.middleName,
        lastName: search.lastName,
        email: search.email,
      })
    )
      .then((res) => {
        const data = res.data.payload;
        setAllAdminData(data.content);
        setCount(data.totalElements);
        setRowsPerPage(data.pageable.pageSize);
        setCurrentPage(data.pageable.pageNumber);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(true);
      });
  };

  const handleSearch = (e) => {
    console.log("handle search clicked");
    let value;
    value = e.target.value;
    setSearch({ [filterBy]: value });
  };

  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteAdmin = () => {
    deleteAdmin(deleteId)
      .then((res) => {
        setDeleteId(null);
        setShowDelete(false);
        getAdminList();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later"
        );
      });
  };

  useEffect(() => {
    getAdminList();
  }, [currentPage, rowsPerPage, search]);

  return (
    <React.Fragment>
      <Helmet title="Admin - List" />
      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Admin?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={submitDeleteAdmin}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Admin List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Admins</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            onClick={() => navigate("/admin/profile")}
            variant="outlined"
            color="primary"
            sx={{ ml: 2 }}
          >
            <Visibility sx={{ mr: 1 }} />
            View Profile
          </Button>

          <Button
            onClick={() => navigate("/admin/profile-update")}
            variant="outlined"
            color="primary"
            sx={{ ml: 2 }}
          >
            <Edit sx={{ mr: 1 }} />
            Update Profile
          </Button>

          <Button
            onClick={() => navigate("/admin/add")}
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add New Admin
          </Button>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>

              <TextField
                size="small"
                label={"Search Here..."}
                variant="outlined"
                onChange={(e) => debounce(() => handleSearch(e), 800)}
              />
            </Stack>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allAdminData.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <AdminTable
                      deleteAdmin={showDeleteModal}
                      admins={allAdminData}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AdminAll;
