import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import {
  Grid,
  Button,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TablePagination,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  Stack,
  IconButton,
  Tooltip,
  Modal,
  Link,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { spacing } from "@mui/system";
import { debounce } from "../../../utils/debounce";
import params from "../../../utils/params";

import NotFound from "../../../components/ui/NotFound";
import ContentLoader from "../../../components/ui/ContentLoader";
import {
  getProduct,
  deleteProduct,
  getAllProducts,
} from "../../../api/product";
import ProductTable from "../../../components/table/ProductTable";
import {
  deleteProductAttribute,
  getAllProductAttribute,
  getProductAttributeById,
} from "../../../api/productAttribute";
import ProductAttributeTable from "../../../components/table/ProductAttributeTable";
import SpecificProductAttributeTable from "../../../components/table/SpecificProductAttributeTable";
import { Delete, Edit } from "@mui/icons-material";
import {
  activeIndicateColor,
  inActiveIndicateColor,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
} from "../../../theme/globalStyles";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: `calc(100% - 40%)`,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  py: 4,
  px: 5,
  borderRadius: "24px",
};

const SpecificProductAttribute = () => {
  // states
  const [allProductAttribute, setProductAttribute] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [count, setCount] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    productAttributeName: "",
    priceIncrement: 0,
    stock: true,
    value: 0,
  });
  const [filterBy, setFilterBy] = useState("productName");
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // objects
  const navigate = useNavigate();
  const params = useParams();
  const productId = params.id;

  const columns = [
    {
      field: "productAttributeName",
      headerName: "Attribute Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "value",
      headerName: "Attribute Value",
      flex: 1,
      sortable: true,
    },
    {
      field: "priceIncrement",
      headerName: "Price",
      type: "number",
      flex: 1,
      sortable: true,
    },
    {
      field: "stock",
      headerName: "In Stock",
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" gap={1} alignItems="center">
          <div
            style={{
              height: "8px",
              width: "8px",
              borderRadius: "8px",
              backgroundColor: params.value
                ? activeIndicateColor
                : inActiveIndicateColor,
            }}
          ></div>
          {params.value ? "Yes" : "No"}
        </Stack>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Stack direction="row" gap={"10px"}>
          <Tooltip title="Edit Product Attribute">
            <IconButton
              color="primary"
              variant="outlined"
              sx={{ ...sxTableActionButtonBorder }}
              onClick={() =>
                navigate(`/product/${productId}/attribute/${params.id}/edit`)
              }
            >
              <Edit />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete Product Attribute">
            <IconButton
              color="error"
              sx={{ ...sxTableActionButtonBorderRed }}
              onClick={() => showDeleteModal(params.id)}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
      flex: 1,
      sortable: false,
    },
  ];

  const filters = [
    {
      label: "Product Attribute Name",
      value: "productAttributeName",
    },
    {
      label: "Product Attribute Price",
      value: "priceIncrement",
    },
    {
      label: "Available in Stock",
      value: "stock",
    },
    {
      label: "Product Attribute Value",
      value: "value",
    },
  ];

  // functions
  // fetch product-attribute
  const fetchProductAttribute = () => {
    setLoading(true);
    getProduct(productId)
      .then((res) => {
        console.log(res.data.payload);
        setProductAttribute(res.data.payload.productAttributes);
        // setCount(res.data.payload.totalElements);
        // setRowsPerPage(res.data.payload.pageable.pageSize);
        // setCurrentPage(res.data.payload.pageable.pageNumber);
        setLoading(false);
      })
      .catch((error) => console.log("Error: ", error));
  };

  // searching
  const handleSearch = (e) => {
    console.log("handle search clicked");
    let value;
    value = e.target.value;
    setSearch({ [filterBy]: value });
  };

  // filtering
  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  // table pagination
  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => setShowDelete(false);

  const submitDeleteProductAttribute = () => {
    deleteProductAttribute(deleteId)
      .then((response) => {
        console.log("Successfully deleted Product Attribute");
        setDeleteId(null);
        setShowDelete(false);
        fetchProductAttribute();
      })
      .catch((error) =>
        console.log("Failed to delete Product Attribute", "error")
      );
  };

  // use-effect
  useEffect(() => {
    fetchProductAttribute();
  }, [currentPage, rowsPerPage, search]);

  return (
    <React.Fragment>
      <Helmet title="Products Attribute - List" />
      <Dialog open={showDelete}>
        <DialogTitle>
          Are you sure to delete this Product Attribute?
        </DialogTitle>
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={submitDeleteProductAttribute}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        justifyContent="space-between"
        alignItems={"end"}
        container
        spacing={10}
      >
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Product Attribute List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/product/all">
              Product
            </Link>
            <Typography>Product Attribute</Typography>
            <Typography>List</Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item>
          <Button
            onClick={() => navigate("/product/all")}
            variant="outlined"
            color="primary"
          >
            Go Back To Products
          </Button>
        </Grid>
      </Grid>

      <Divider my={3} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            {/* <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                size="small"
                label="Search here"
                variant="outlined"
                onChange={(e) => debounce(() => handleSearch(e), 800)}
              />
            </Stack> */}
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {allProductAttribute.length < 1 ? (
                  <NotFound title="This product don't have any attributes" />
                ) : (
                  <>
                    <Box
                      sx={{
                        height: "400px",
                        width: "100%",
                      }}
                    >
                      <DataGrid
                        rows={allProductAttribute}
                        columns={columns}
                        pageSize={20}
                        rowsPerPageOptions={[20, 50, 100, 200]}
                        components={{ Toolbar: GridToolbar }}
                        sx={{
                          "[aria-label = Export]": {
                            display: "none ",
                          },
                        }}
                      />
                    </Box>
                    {/* <SpecificProductAttributeTable
                      deleteProductAttribute={showDeleteModal}
                      rows={allProductAttribute}
                    />
                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    /> */}
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default SpecificProductAttribute;
