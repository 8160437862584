import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import styled from "styled-components/macro";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Stack,
  Autocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";
import { getAllAlbum } from "../../api/album";
import ErrorAlert from "../ui/ErrorAlert";
import ImageUpload from "../ui/ImageUpload";
import ImageFieldError from "../ui/ImageFieldError";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const MemoryForm = (props) => {
  // objects
  const { memory, errorMessage, isSubmittingMemory } = props;

  // state
  const [allAlbum, setAllAlbum] = useState([]);
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // functions
  const fetchAllAlbum = () => {
    getAllAlbum()
      .then((res) => {
        const response = res.data.payload.content;
        console.log("all album: ", response);
        setAllAlbum(response);
      })
      .catch((error) => console.log(`Error: ${error.response.data.message}`));
  };

  // validation
  const validationSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is Required"),
    albumId: Yup.object().nullable().required("Album is required"),
  });

  // initial values
  const initialValues =
    Object.keys(memory).length !== 0
      ? {
          image: memory.image,
          albumId: allAlbum.find((album) => album.id === memory.albumId),
          caption: memory.caption,
          description: memory.description,
          memoryPhotoId: memory.id,
        }
      : {
          image: null,
          albumId: null,
          caption: "",
          description: "",
        };

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    fetchAllAlbum();
    setHideDeleteBtn(memory?.image ? false : true);
  }, [memory?.image]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container>
                {/* alert */}
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                {/* image upload */}
                <Grid item xs={12} mb={3}>
                  <ImageUpload
                    name="image"
                    fullWidth
                    landScape={false}
                    hideDeleteBtn={hideDeleteBtn}
                    onRemove={() => {
                      setFieldValue("image", null);
                      setHideDeleteBtn(true);
                    }}
                    onChange={(event) => {
                      setFieldValue("image", event.target.files[0]);
                      setHideDeleteBtn(false);
                    }}
                    onBlur={handleBlur}
                    imgSrc={
                      values?.image?.fileUrl
                        ? values?.image?.fileUrl
                        : values.image
                        ? URL.createObjectURL(values.image)
                        : "/upload-placeholder.jpg"
                    }
                  />

                  <ImageFieldError errors={errors} touched={touched} />
                </Grid>

                {/* caption */}
                <Grid item xs={12} md={6} pr={{ xs: 0, md: 4 }}>
                  <TextField
                    name="caption"
                    label="Memory Name"
                    fullWidth
                    size="small"
                    value={values.caption || ""}
                    error={Boolean(touched.caption && errors.caption)}
                    helperText={touched.caption && errors.caption}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                {/* select album */}
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    onOpen={handleBlur}
                    value={values.albumId}
                    onChange={(event, value) => setFieldValue("albumId", value)}
                    options={allAlbum}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Album"
                        size="small"
                        placeholder="Select Album"
                        error={Boolean(touched.albumId && errors.albumId)}
                        helperText={touched.albumId && errors.albumId}
                        my={2}
                      />
                    )}
                  />
                </Grid>

                {/* description */}
                <Grid item xs={12}>
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    size="small"
                    name="description"
                    label="Memory Description"
                    value={values.description || ""}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              {/* action buttons */}
              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => props.navigate("/memory/all")}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmittingMemory}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default MemoryForm;
