import { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import {
  Stack,
  Box,
  Divider,
  Breadcrumbs,
  Link,
  Typography,
  Button,
  Grid,
  Card,
  TextField,
  TablePagination,
  DialogTitle,
  Dialog,
  DialogActions,
} from "@mui/material";
import AllBlogComponent from "../../components/blog/AllBlogComponent";
import ContentLoader from "../../components/ui/ContentLoader";
import { useNavigate, NavLink } from "react-router-dom";
import BlogTable from "../../components/table/BlogTable";
import { deleteBlog, getAllBlogs } from "../../api/blog";
import params from "../../utils/params";
import { debounce } from "../../utils/debounce";
import NotFound from "../../components/ui/NotFound";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ErrorAlert from "../../components/ui/ErrorAlert";

const AllBlogs = () => {
  // states
  const [loading, setLoading] = useState(true);
  const [disbleBlogButton, setDisbleBlogButton] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [count, setCount] = useState(1);
  const [deleteId, setDeleteId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState({
    title: "",
    subtitle: "",
  });
  const [filterBy, setFilterBy] = useState("title");
  const [errorMessage, setErrorMessage] = useState("");

  // objects
  const navigate = useNavigate();

  const filters = [
    {
      label: "Title",
      value: "title",
    },
    {
      label: "Sub Title",
      value: "subtitle",
    },
  ];

  // functions
  // fetch products
  const fetchBlog = () => {
    setLoading(true);
    getAllBlogs(
      params({
        pageNo: currentPage,
        pageSize: rowsPerPage,
        sortBy: "lastModifiedDate",
        ascOrDesc: "desc",
        title: search.title,
        subtitle: search.subtitle,
      })
    )
      .then((res) => {
        console.log("all blogs", res);
        setBlogs(res.data.payload.content);
        setCount(res.data.payload.totalElements);
        setRowsPerPage(res.data.payload.pageable.pageSize);
        setCurrentPage(res.data.payload.pageable.pageNumber);
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error: ", error);
        setLoading(false);
      });
  };

  const getAllBlogsForAddNewBlog = () => {
    getAllBlogs()
      .then((res) => {
        if (res.data.payload.content.length >= 3) {
          setDisbleBlogButton(true);
        } else {
          setDisbleBlogButton(false);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setLoading(false);
      });
  };

  // searching
  const handleSearch = (e) => {
    console.log("handle search clicked");
    let value;
    value = e.target.value;
    setSearch({ [filterBy]: value });
  };

  // filtering
  const handleFilter = (e) => {
    console.log("Handle Filter clicked!");
    const filter = e.target.value;
    setFilterBy(filter);
    setSearch({});
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    console.log("handle rows per page", e.target.value);
  };

  const createBlogHandler = () => {
    navigate("/blog/create");
  };

  // delete operation
  const showDeleteModal = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const hideDeleteModal = () => {
    setErrorMessage("");
    setShowDelete(false);
  };

  const submitDeleteBlog = () => {
    deleteBlog(deleteId)
      .then((response) => {
        setDeleteId(null);
        setShowDelete(false);
        fetchBlog();
        getAllBlogsForAddNewBlog();
      })
      .catch((error) => {
        console.log(error.response.data.message);
        setErrorMessage(
          error.response.data.message ||
            "Something went wrong, please try again later"
        );
      });
  };

  useEffect(() => {
    fetchBlog();
    getAllBlogsForAddNewBlog();
  }, [currentPage, rowsPerPage, search]);

  return (
    <Fragment>
      <Helmet title="Blog - List" />

      <Dialog open={showDelete}>
        <DialogTitle>Are you sure to delete this Blog?</DialogTitle>
        {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
        <DialogActions>
          <Button variant="outlined" onClick={hideDeleteModal}>
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={submitDeleteBlog}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Typography variant="h3" gutterBottom display="inline">
        Blog List
      </Typography>

      <Stack
        direction="row"
        alignItems={"end"}
        justifyContent={"space-between"}
      >
        <Box>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>All Role</Typography>
          </Breadcrumbs>
        </Box>
        <Box>
          <Button
            disabled={disbleBlogButton}
            variant="contained"
            onClick={createBlogHandler}
          >
            <AddCircleOutlineIcon sx={{ mr: 1 }} />
            Add New Blog
          </Button>
        </Box>
      </Stack>

      <Divider sx={{ my: 3 }} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card sx={{ p: 5 }}>
            <Stack direction="row" gap={3} mb={3}>
              <TextField
                id="outlined-native"
                select
                size="small"
                label="Filter By"
                value={filterBy}
                onChange={handleFilter}
                SelectProps={{
                  native: true,
                }}
              >
                {filters.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                size="small"
                label="Search here"
                variant="outlined"
                onChange={(e) => debounce(() => handleSearch(e), 800)}
              />
            </Stack>
            {loading ? (
              <ContentLoader />
            ) : (
              <>
                {blogs.length < 1 ? (
                  <NotFound />
                ) : (
                  <>
                    <BlogTable rows={blogs} deleteBlog={showDeleteModal} />

                    <TablePagination
                      rowsPerPageOptions={[20, 50, 100]}
                      count={count}
                      page={currentPage}
                      rowsPerPage={rowsPerPage}
                      onPageChange={(_, page) => {
                        handleChangePage(page);
                      }}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      component="div"
                    />
                  </>
                )}
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AllBlogs;
