function createShadow() {
  return `box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);`;
}

function cardHoverBoxShadow() {
  return `box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10);`;
}

const shadows = [
  "none",
  createShadow(),
  cardHoverBoxShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
  createShadow(),
];

export default shadows;
