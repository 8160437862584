import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import * as Yup from "yup";
import { Formik } from "formik";

import {
  Alert as MuiAlert,
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

// import useAuth from "../../hooks/useAuth";
import { changePasswordApi } from "../../api/auth";

const Alert = styled(MuiAlert)(spacing);

const TextField = styled(MuiTextField)(spacing);

function ChangePassword() {
  const navigate = useNavigate();
  // const { resetPassword } = useAuth();

  return (
    <Formik
      initialValues={{
        email: "",
        newPassword: "",
        previousPassword: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        newPassword: Yup.string().max(255).required("New Password is required"),
        previousPassword: Yup.string()
          .max(255)
          .required("Previous Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log(values);
        try {
          const data = {
            email: values.email,
            newPassword: values.newPassword,
            previousPassword: values.previousPassword,
          };
          changePasswordApi(data);
          navigate("/auth/sign-in");
        } catch (error) {
          const message = error.message || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
            mt={3}
          />

          <TextField
            type="password"
            name="previousPassword"
            label="Previous Password"
            value={values.previousPassword}
            error={Boolean(touched.previousPassword && errors.previousPassword)}
            fullWidth
            helperText={touched.previousPassword && errors.previousPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />

          <TextField
            type="password"
            name="newPassword"
            label="New Password"
            value={values.newPassword}
            error={Boolean(touched.newPassword && errors.newPassword)}
            fullWidth
            helperText={touched.newPassword && errors.newPassword}
            onBlur={handleBlur}
            onChange={handleChange}
            my={3}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Change Password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default ChangePassword;
