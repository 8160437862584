import { Box, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { CameraAltOutlined, Landscape } from "@mui/icons-material";
import {
  inActiveIndicateColor,
  sxUpperDeleteIcon,
} from "../../theme/globalStyles";
import ClearIcon from "@mui/icons-material/Clear";

const ImageUpload = ({
  name,
  imgSrc,
  onChange,
  onRemove,
  inputProps,
  containerProps,
  landScape,
  multiple,
  hideDeleteBtn = true,
}) => {
  return (
    <Box
      className="img-upload__wrapper"
      sx={{
        width: "100%",
      }}
    >
      <Box
        className="img-upload__container"
        sx={{
          width: landScape ? "266px" : "200px",
          height: "200px",
          margin: "0 auto",
          position: "relative",
          overflow: "hidden",
          background: "#f5f5f5",
          border: `1px solid #d9dede`,
          borderRadius: "5px",
        }}
        {...containerProps}
      >
        <label htmlFor="image-upload">
          <CameraAltOutlined
            sx={{
              width: landScape ? "266px" : "200px",
              height: "200px",
              opacity: 0,
            }}
          />

          <input
            hidden
            type="file"
            name={name}
            multiple={multiple}
            accept="image/*"
            id="image-upload"
            onChange={onChange}
            onClick={(event) => (event.target.value = null)}
            {...inputProps}
          />
        </label>

        {hideDeleteBtn === false ? (
          <IconButton color="error" sx={sxUpperDeleteIcon} onClick={onRemove}>
            <ClearIcon />
          </IconButton>
        ) : (
          ""
        )}

        {imgSrc && (
          <img
            alt={name}
            src={
              imgSrc ??
              (Landscape ? "/upload-placeholder.jpg" : "/default-image.png")
            }
            loading="lazy"
            style={{
              height: "100%",
              width: "100%",
              objectFit: landScape ? "cover" : "cover",
            }}
          />
        )}
      </Box>

      <style jsx={"true"}>
        {`
          .img-upload__container label {
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.3;
          }
          .img-upload__container label::after {
            content: "upload image";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
          }
          .remove__btn {
            position: absolute;
            right: 3px;
            top: 3px;
            height: 25px;
            width: 25px;
            line-height: 25px;
            border: 1px solid ${inActiveIndicateColor};
            border-radius: 100%;
            padding: 0;
            background: white;
            cursor: pointer;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
              rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
          }
          .remove__btn span {
            color: red;
            font-size: 20px;
          }
          .img-upload__container input[type="file"] {
            bottom: 0;
            left: 0;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 0;
            height: 200px;
            width: 200px;
            z-index: -1;
          }
        `}
      </style>
    </Box>
  );
};

ImageUpload.propTypes = {
  name: PropTypes.string.isRequired,
  imgSrc: PropTypes.string,
  containerProps: PropTypes.object,
  inputProps: PropTypes.object,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  landScape: PropTypes.bool,
  multiple: PropTypes.bool,
  hideDeleteBtn: PropTypes.bool,
};

export default ImageUpload;
