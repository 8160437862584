import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";

import ViewBlog from "../modals/ViewBlog";
import {
  sxFancyTableBorder,
  imageDefaultStyleCover,
  sxTableHeadBG,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
} from "../../theme/globalStyles";
import { deleteBlog, getAllBlogs } from "../../api/blog";
import { customGreen } from "../../theme/variants";

export default function BlogTable(props) {
  const { deleteBlog } = props;

  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Sub Title</TableCell>
            <TableCell>Blog Type</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                <Box
                  sx={{
                    height: "100px",
                    width: "150px",
                    overflow: "hidden",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={row.coverImage?.fileUrl ?? "/default-image.png"}
                    alt="default"
                    style={{
                      ...imageDefaultStyleCover,
                      objectFit: "contain",
                      borderRadius: "8px",
                    }}
                  />
                </Box>
              </TableCell>
              <TableCell>{row.title}</TableCell>
              <TableCell>{row.subtitle}</TableCell>
              <TableCell>{row.blogType}</TableCell>
              <TableCell>
                <div style={{ display: "flex", gap: "5px" }}>
                  <ViewBlog data={row} />
                  <IconButton
                    color="primary"
                    sx={{ ...sxTableActionButtonBorder }}
                    onClick={() => navigate(`/blog/edit/${row.id}`)}
                  >
                    <Edit />
                  </IconButton>

                  <IconButton
                    color="error"
                    disabled={false}
                    sx={{ ...sxTableActionButtonBorderRed }}
                    onClick={() => deleteBlog(row.uuid)}
                  >
                    <Delete />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
