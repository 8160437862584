import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import { Edit, Delete } from "@mui/icons-material";
import {
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  activeIndicateColor,
  inActiveIndicateColor,
  imageDefaultStyleCover,
} from "../../theme/globalStyles";

export default function EventAddressTable(props) {
  const navigate = useNavigate();

  const { allEventAddress } = props;
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px",
          },
          // "table, td, th": {
          //   borderRight: "1px solid #d9dede",
          //   "&:last-of-type": {
          //     borderRight: "none",
          //   },
          // },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell align="left">Event Id</TableCell>
            <TableCell align="left">Town</TableCell>
            <TableCell align="left">Latitude</TableCell>
            <TableCell align="left">Longitude</TableCell>
            <TableCell align="left">Postal Code</TableCell>
            <TableCell align="left">State</TableCell>
            <TableCell align="left">Country</TableCell>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allEventAddress?.map((row, index) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.eventId ?? "Not Found"}</TableCell>

              <TableCell align="left">{row.town ?? "Not Found"}</TableCell>

              <TableCell align="left">{row.lattitude ?? "Not Found"}</TableCell>

              <TableCell align="left">{row.longitude ?? "Not Found"}</TableCell>

              <TableCell align="left">
                {row.postalCode ?? "Not Found"}
              </TableCell>

              <TableCell align="left">{row.state ?? "Not Found"}</TableCell>

              <TableCell align="left">{row.country ?? "Not Found"}</TableCell>

              <TableCell align="left">{row.addressLine1}</TableCell>

              <TableCell align="left">
                <div style={{ display: "flex", gap: "5px" }}>
                  <IconButton
                    color="primary"
                    variant="outlined"
                    sx={{ ...sxTableActionButtonBorder }}
                    onClick={() => navigate(`/event/address/edit/${row.id}`)}
                  >
                    <Edit />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
