import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover as MuiPopover,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { Bell } from "react-feather";
import {
  getAllNotification,
  getUnreadNotificationCounter,
  markedAsRead,
} from "../../api/notification";
import params from "../../utils/params";
import useNotification from "../../hooks/useNotification";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NotificationHeader = styled(Box)`
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`;

function Notification({ title, message, Icon, routeUrl }) {
  return (
    <ListItem divider component={Link} to={routeUrl}>
      <ListItemAvatar>
        <Avatar>
          <SvgIcon fontSize="small">
            <Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          variant: "subtitle2",
          color: "textPrimary",
        }}
        secondary={message}
      />
    </ListItem>
  );
}

function NavbarNotificationsDropdown() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const [allNotificationInfo, setAllNotificationInfo] = useState([]);

  const [notificationCounter, setNotificationCounter] = useState(0);

  const { getNotificationIcon, getNotificationUrl } = useNotification();

  // fetch notification
  const fetchNotifications = () => {
    // notification counter
    getUnreadNotificationCounter()
      .then((res) => {
        const data = res.data.payload;

        setNotificationCounter(data);
      })
      .catch((error) => console.log("Error: ", error));

    // notification content
    getAllNotification(
      params({
        sortBy: "lastModifiedDate",
        ascOrDesc: "desc",
      })
    )
      .then((res) => {
        const data = res.data.payload;
        setAllNotificationInfo(data.content);
      })
      .catch((error) => console.log("Error: ", error));
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // marked as read notification
  const markedAsReadNotification = () => {
    const data = {};

    markedAsRead(data)
      .then((res) => {
        setNotificationCounter(0);
      })
      .catch((error) => console.log(error.response.data.message));
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={notificationCounter}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <NotificationHeader p={2}>
          <Typography variant="subtitle1" color="textPrimary">
            {notificationCounter} New Notifications
          </Typography>
        </NotificationHeader>
        <React.Fragment>
          <List disablePadding>
            {notificationCounter > 0 && allNotificationInfo.length > 0 ? (
              allNotificationInfo
                ?.slice(0, 3)
                .map((notificationData) => (
                  <Notification
                    key={notificationData.id}
                    title={notificationData?.title ?? "Title"}
                    message={notificationData?.message ?? "Message"}
                    Icon={getNotificationIcon(notificationData?.type)}
                    routeUrl={getNotificationUrl(notificationData?.type)}
                  />
                ))
            ) : (
              <Typography my={3} variant="h4" textAlign={"center"}>
                there is no new notification
              </Typography>
            )}
          </List>
          <Stack direction="row" gap={1} justifyContent="space-between">
            <Box width="50%" textAlign="center">
              <Button
                size="small"
                onClick={() => {
                  setOpen(false);
                  navigate("/notification/all");
                }}
                sx={{ py: 2, px: 1, width: "100%" }}
              >
                See All Notification
              </Button>
            </Box>
            <Box width="50%" textAlign="center">
              {notificationCounter > 0 ? (
                <Button
                  size="small"
                  onClick={() => markedAsReadNotification()}
                  sx={{ py: 2, px: 1, width: "100%" }}
                >
                  Marked As Read
                </Button>
              ) : (
                <Button
                  size="small"
                  disabled={true}
                  sx={{ py: 2, px: 1, width: "100%" }}
                >
                  Marked As Read
                </Button>
              )}
            </Box>
          </Stack>
        </React.Fragment>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
