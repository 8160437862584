import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  IconButton,
  Grid,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import {
  sxFancyTableBorder,
  sxTableActionButtonBorderRed,
  imageDefaultStyleCover,
  sxUpperDeleteIcon,
} from "../../theme/globalStyles";
import { getAlbumById, updateAlbum } from "../../api/album";
import ClearIcon from "@mui/icons-material/Clear";

export default function AlbumMemoryTable(props) {
  // states
  // const [album, setAlbum] = useState({});

  // objects
  const { allMemory, deleteMemory } = props;

  const navigate = useNavigate();

  const setImageAsAlbumCoverPhoto = (memoryId, albumId, imageId) => {
    // get the specific albub data
    getAlbumById(albumId)
      .then((res) => {
        const responseData = res.data.payload;
        console.log("Album response from memory: ", responseData);

        // set data for update
        let data = {
          active: responseData.active,
          albumId: albumId,
          description: responseData.description,
          memoryPhotoId: memoryId,
          name: responseData.name,
        };

        updateAlbum(data)
          .then((res) => console.log("Successfully Set Album Cover"))
          .catch((error) =>
            console.log(`Error: ${error.response.data.message}`)
          );
      })
      .catch((error) => console.log(`Error: ${error.response.data.message}`));
  };

  // sx props
  const sxMemoryGridItem = {
    height: "200px",
    borderRadius: "8px",
    overflow: "hidden",
    position: "relative",
  };

  const sxMemoryDeleteIconButton = {
    ...sxUpperDeleteIcon,
    top: 25,
  };

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "16px",
          },
        }}
        aria-label="simple table"
      >
        <TableBody>
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell align="left">
              <Grid container spacing={5} alignItems={"center"}>
                {allMemory?.map((item) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    lg={3}
                    xl={2}
                    key={item.id}
                    sx={sxMemoryGridItem}
                  >
                    <img
                      className="memory-img"
                      src={item?.image?.fileUrl ?? "/default-image.png"}
                      alt={item?.image?.fileName ?? item?.name}
                      style={{
                        ...imageDefaultStyleCover,
                        borderRadius: "8px",
                        border: `1px solid #d9dede`,
                      }}
                    />

                    <IconButton
                      color="error"
                      sx={sxMemoryDeleteIconButton}
                      onClick={() => deleteMemory(item.id)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
