import { Alert } from '@mui/material'
import React from 'react'

const ErrorAlert = ({ title, ...params }) => {
  return (
    <Alert severity="error" sx={{ ...params }}>
      {title}
    </Alert>
  );
};

export default ErrorAlert