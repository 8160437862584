import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";

import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Stack,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
} from "@mui/material";
import { spacing } from "@mui/system";
import ErrorAlert from "../ui/ErrorAlert";
import ImageUpload from "../ui/ImageUpload";
import ImageFieldError from "../ui/ImageFieldError";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const ProductSliderForm = (props) => {
  // objects
  const { slider, errorMessage, isSubmittingSlider } = props;

  // states
  const [hideDeleteBtn, setHideDeleteBtn] = useState(true);

  // validation
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Slider Title is required"),

    active: Yup.boolean().required("Status is required"),

    image: Yup.mixed().required("Image is Required"),
  });

  // initial values
  const initialValues =
    Object.keys(slider).length !== 0
      ? {
          image: slider.imageFile,
          title: slider.title,
          message: slider.message,
          active: slider.active,
        }
      : {
          image: null,
          title: "",
          active: true,
          message: "",
        };

  useEffect(() => {
    setHideDeleteBtn(slider?.imageFile ? false : true);
  }, [slider?.imageFile]);

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // use-effect
  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  {errorMessage && <ErrorAlert title={errorMessage} mb={3} />}
                </Grid>

                <Grid item xs={12} mb={3}>
                  <ImageUpload
                    name="image"
                    landScape={false}
                    fullWidth
                    onBlur={handleBlur}
                    hideDeleteBtn={hideDeleteBtn}
                    onRemove={() => {
                      setFieldValue("image", null);
                      setHideDeleteBtn(true);
                    }}
                    onChange={(event) => {
                      setFieldValue("image", event.target.files[0]);
                      setHideDeleteBtn(false);
                    }}
                    imgSrc={
                      values?.image?.fileUrl
                        ? values?.image?.fileUrl
                        : values.image
                        ? URL.createObjectURL(values.image)
                        : "/upload-placeholder.jpg"
                    }
                  />

                  <ImageFieldError errors={errors} touched={touched} />
                </Grid>

                <Grid item xs={12} md={6} pr={{ xs: 0, md: 4 }}>
                  <TextField
                    name="title"
                    label="Slider Title"
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl sx={{ my: 2 }} fullWidth>
                    <InputLabel id="select-active">Is Active</InputLabel>
                    <Select
                      name="active"
                      labelId="select-active"
                      value={values.active}
                      error={Boolean(touched.active && errors.active)}
                      fullWidth
                      label="Is Active"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="message"
                    label="Slider Message"
                    value={values.message}
                    error={Boolean(touched.message && errors.message)}
                    helperText={touched.message && errors.message}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() => props.navigate("/product-slider/all")}
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isSubmittingSlider}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default ProductSliderForm;
