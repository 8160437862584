import axios from "../utils/axios";

// get all products
export const getAllProducts = (filters) => {
  return axios.get(`api/products/all?${filters}`);
};

// create product
export const createProduct = (data) => {
  return axios.post("api/products/create", data);
};

// get specific product
export const getProduct = (id) => {
  return axios.get(`api/products/id/${id}`);
};

// update product
export const updateProduct = (data) => {
  return axios.put("api/products/update", data);
};

// delete product
export const deleteProduct = (id) => {
  return axios.delete(`api/products/delete/id/${id}`);
};
