import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  IconButton,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import {
  sxFancyTableBorder,
  sxTableHeadBG,
  activeIndicateColor,
  inActiveIndicateColor,
  sxTableActionButtonBorderRed,
} from "../../theme/globalStyles";
import TimeFormat from "../../utils/timeFormat";
import { customGreen } from "../../theme/variants";
import useNotification from "../../hooks/useNotification";
import { Delete } from "@mui/icons-material";

export default function NotificationTable({
  allNotification,
  deleteNotification,
}) {
  const navigate = useNavigate();

  const { getNotificationUrl } = useNotification();
  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          td: {
            padding: "8px",
          },
          // "table, td, th": {
          //   borderRight: "1px solid #d9dede",
          //   "&:last-of-type": {
          //     borderRight: "none",
          //   },
          // },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Message</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="left">Read</TableCell>
            <TableCell align="left">Sender</TableCell>
            <TableCell align="left">Created At</TableCell>
            <TableCell align="left">Action</TableCell>
          </TableRow>
          {console.log("notification: ", allNotification)}
        </TableHead>
        <TableBody>
          {allNotification?.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                cursor: "pointer",
                "&:hover": { backgroundColor: customGreen[50] },
              }}
              // onClick={() => navigate(getNotificationUrl(row?.type))}
            >
              <TableCell align="left">{row.title}</TableCell>

              <TableCell align="left">{row.message}</TableCell>

              <TableCell align="left" sx={{ textTransform: "capitalize" }}>
                {row.type?.replace("_", " ").toLowerCase()}
              </TableCell>

              <TableCell align="left">
                <Stack direction="row" gap={1} alignItems="center">
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      borderRadius: "8px",
                      backgroundColor: row.read
                        ? activeIndicateColor
                        : inActiveIndicateColor,
                    }}
                  ></div>
                  {row.read ? "Yes" : "No"}
                </Stack>
              </TableCell>

              <TableCell align="left">{row?.sender?.name ?? "N/A"}</TableCell>

              <TableCell align="left">
                {TimeFormat(row.creationDateTimeStamp)}
              </TableCell>

              <TableCell>
                <IconButton
                  color="error"
                  sx={{ ...sxTableActionButtonBorderRed }}
                  onClick={() => deleteNotification(row.id)}
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
