// debounce click
// let timeoutId;
// export const debounce = (fn, delay) => {
//   if (timeoutId) {
//     clearTimeout(timeoutId);
//   }
//   timeoutId = setTimeout(() => {
//     fn();
//   }, delay);
// };

let timeout;
export const debounce = function (func, delay) {
  clearTimeout(timeout);

  timeout = setTimeout(func, delay);
};
