import axios from "../utils/axios";

// get all notification
export const getAllNotification = (filters) => {
  return axios.get(`api/notification/all?${filters}`);
};

// get unread notification number
export const getUnreadNotificationCounter = () => {
  return axios.get("api/notification/unread-counter");
};

// marked as read
export const markedAsRead = (data) => {
  return axios.post("api/notification/mark-as-read", data);
};

// send custom notification to user
export const sendNotification = (data) => {
  return axios.post("api/custom-notification/push-custom-notification", data);
};

// delete a notification item
export const deleteNotification = (id) => {
  return axios.delete(`api/notification/delete/${id}`);
};
