import axios from "../utils/axios";

export const getOrderMonthly = () => {
  return axios.get("api/admin-dashboard/order-monthly");
};

export const getOrderWeekly = () => {
  return axios.get("api/admin-dashboard/order-weekly");
};

export const getUsersMonthly = () => {
  return axios.get("api/admin-dashboard/users-monthly");
};

export const getUsersWeekly = () => {
  return axios.get("api/admin-dashboard/users-weekly");
};

export const getActiveUsersMonthly = () => {
  return axios.get("api/admin-dashboard/active-users-monthly");
};

export const getRetiredUsersMonthly = () => {
  return axios.get("api/admin-dashboard/retired-users-monthly");
};
