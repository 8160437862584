import { React, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { imageDefaultStyleCover } from "../../theme/globalStyles";
import { customGreen } from "../../theme/variants";
import {
  Grid,
  Box,
  Link,
  Card,
  Divider,
  Stack,
  Button,
  Typography,
  Breadcrumbs,
} from "@mui/material";
import { getAdminInfo } from "../../api/admin";
import { useNavigate, NavLink } from "react-router-dom";
import { Fragment } from "react";

export const ProfileDataRow = ({ title, value }) => {
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      gap={2}
      sx={{
        py: 2,
        px: 4,
        overflowX: { xs: "scroll", md: "hidden" },
        borderBottom: customGreen[200],
        "&:first-of-type": {},
        "&:last-of-type": { borderBottom: "none" },
      }}
    >
      <Typography variant="h4" fontWeight={700} sx={{}}>
        {`${title}: `}
      </Typography>

      <Typography variant="h6" sx={{}}>
        {value}
      </Typography>
    </Stack>
  );
};

const AdminProfile = () => {
  const [adminData, setAdminData] = useState(null);

  const navigate = useNavigate();

  const adminInformation = [
    { id: 1, title: "First Name", value: adminData?.firstName ?? "" },
    { id: 2, title: "Middle Name", value: adminData?.middleName ?? "" },
    { id: 3, title: "Last Name", value: adminData?.lastName ?? "" },
    { id: 4, title: "Email", value: adminData?.email ?? "" },
  ];

  const fetchAdminInfo = async () => {
    try {
      let response = await getAdminInfo();
      let adminData = response?.data?.payload;
      setAdminData(adminData);
    } catch (error) {
      console.log(
        "Admin Info Error: ",
        error?.response?.data?.message ?? error
      );
    }
  };

  const gotoUpdateProfileHandler = () => {
    navigate("/admin/profile-update", { replace: true });
  };

  useEffect(() => {
    fetchAdminInfo();
  }, []);

  return (
    <div>
      <Helmet title="Profile" />

      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"end"}
        mb={3}
      >
        <Box>
          <Typography
            variant="h3"
            gutterBottom
            display="flex"
            alignItems="center"
          >
            <span>{`${adminData?.firstName ?? ""} ${
              adminData?.lastName ?? ""
            }`}</span>
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>

            <Link component={NavLink} to="/admin/all">
              Admins
            </Link>

            <Typography>Profile</Typography>
          </Breadcrumbs>
        </Box>

        <Button variant="outlined" onClick={gotoUpdateProfileHandler}>
          Update Profile
        </Button>
      </Stack>

      <Divider />
      <Card sx={{ mt: 4, p: { xs: 5, md: 10 } }}>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          columnSpacing={20}
          rowSpacing={10}
        >
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                width: "250px",
                minWidth: "250px",
                height: "250px",
                minHeight: "250px",
                margin: "0 auto",
                padding: "8px",
                overflow: "hidden",
                borderRadius: "50%",
                border: `1px solid ${customGreen[200]}`,
              }}
            >
              <img
                src={adminData?.displayPhoto?.fileUrl ?? "/cov.png"}
                alt="user-profile"
                style={{
                  ...imageDefaultStyleCover,
                  borderRadius: "50%",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Card
              sx={{
                border: `1px solid ${customGreen[200]}`,
              }}
            >
              {adminInformation.map((admin) => (
                <ProfileDataRow
                  key={admin.id}
                  title={admin.title}
                  value={admin.value ?? "unavailable"}
                />
              ))}
            </Card>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default AdminProfile;
