import axios from "../utils/axios";

// get all product-attribute
export const getAllProductAttribute = (filters) => {
  return axios.get(`api/product-attributes/all?${filters}`);
};

// create product attribute
export const createProductAttribute = (data) => {
  return axios.post("api/product-attributes/create", data);
};

// get a product attribute by id
export const getProductAttributeById = (id) => {
  return axios.get(`api/product-attributes/${id}`);
};

// update product attribute
export const updateProductAttribute = (data) => {
  return axios.put("api/product-attributes/update", data);
};

// delete product attribute
export const deleteProductAttribute = (id) => {
  return axios.delete(`api/product-attributes/delete/id/${id}`);
};
