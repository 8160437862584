import axios from "../utils/axios";

// get all memory
export const getAllMemory = (filters) => {
  return axios.get(`api/memory-photos/all?${filters}`);
};

// create memory
export const createMemory = (data) => {
  return axios.post("api/memory-photos/create", data);
};

// get a memroy
export const getMemoryById = (id) => {
  return axios.get(`api/memory-photos/id/${id}`);
};

// update memory
export const updateMemory = (data) => {
  return axios.put("api/memory-photos/update", data);
};

// delete a memory
export const deleteMemory = (id) => {
  return axios.delete(`api/memory-photos/delete/id/${id}`);
};
