import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

import {
  activeIndicateColor,
  imageDefaultStyleCover,
  inActiveIndicateColor,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
  sxTableActionButtonBorderRed,
  sxTableHeadBG,
  sxTableImageContainerStyle,
} from "../../theme/globalStyles";
import { CheckCircle, XCircle } from "react-feather";
import { SendOutlined, Visibility } from "@mui/icons-material";
import TimeFormat from "../../utils/timeFormat";

export default function LatestUserTable(props) {
  const navigate = useNavigate();

  return (
    <TableContainer>
      <Table
        sx={{
          minWidth: 650,
          ...sxFancyTableBorder,
          ".MuiTableCell-root": { p: "10px" },
        }}
        aria-label="simple table"
      >
        <TableHead sx={{ ...sxTableHeadBG }}>
          <TableRow>
            <TableCell>First Name</TableCell>

            <TableCell>Middle Name</TableCell>

            <TableCell>Last Name</TableCell>

            <TableCell>Email</TableCell>

            <TableCell>Joining Date</TableCell>

            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.latestUser.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>{row.firstName ?? ""}</TableCell>

              <TableCell>{row.middleName ?? ""}</TableCell>

              <TableCell>{row.lastName ?? ""}</TableCell>

              <TableCell>{row.email ?? ""}</TableCell>

              <TableCell>{TimeFormat(row.creationDateTimeStamp)}</TableCell>

              <TableCell align="center">
                <Stack
                  direction="row"
                  gap={2}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Tooltip title="View User">
                    <IconButton
                      color="secondary"
                      sx={{ ...sxTableActionButtonBorder }}
                      onClick={() => navigate(`/user/${row.id}`)}
                    >
                      <Visibility />
                    </IconButton>
                  </Tooltip>
                  <Box>
                    {row.verified ? (
                      <Tooltip title="Verified User">
                        <IconButton
                          color="secondary"
                          sx={{
                            ...sxTableActionButtonBorder,
                            color: activeIndicateColor,
                            cursor: "default",
                          }}
                        >
                          <CheckCircle />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Un-Verified User">
                        <IconButton
                          color="error"
                          sx={{
                            ...sxTableActionButtonBorderRed,
                            color: inActiveIndicateColor,
                            cursor: "default",
                          }}
                        >
                          <XCircle />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
