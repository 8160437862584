import axios from "../utils/axios";

// get all address
export const getAllAddress = (filters) => {
  return axios.get(`api/address/all?${filters}`);
};

// create address
export const createAddress = (data) => {
  return axios.post("api/address/create", data);
};

// get a address
export const getAddressById = (id) => {
  return axios.get(`api/address/id/${id}`);
};

// update address
export const updateAddress = (data) => {
  return axios.put("api/address/update", data);
};
