import axios from "../utils/axios";

// get all slider
export const getAllSlider = (filters) => {
  return axios.get(`api/walkthrough/all?${filters}`);
};

// create a slider
export const createSlider = (data) => {
  return axios.post("api/walkthrough/create", data);
};

// get a slider
export const getSliderById = (id) => {
  return axios.get(`api/walkthrough/id/${id}`);
};

// update slider
export const updateSlider = (data) => {
  return axios.put("api/walkthrough/update", data);
};

// delete slider
export const deleteSlider = (id) => {
  return axios.delete(`api/walkthrough/delete/id/${id}`);
}
