import React, { useState } from "react";
import {
  Box,
  Modal,
  IconButton,
  Typography,
  Button,
  Stack,
  Divider,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
// import styled from "styled-components/macro";
import variants from "../../theme/variants";
import {
  imageDefaultStyleCover,
  sxTableActionButtonBorder,
} from "../../theme/globalStyles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  boxShadow: 24,
  py: 4,
  px: 5,
  borderRadius: "24px",
};

export default function ViewModal({ data }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        color="secondary"
        sx={{ ...sxTableActionButtonBorder }}
        onClick={handleOpen}
      >
        <Visibility />
      </IconButton>
      <Modal open={open} onClose={handleClose} sx={{ borderRadius: "24px" }}>
        <Box sx={style}>
          <Typography
            variant="h3"
            align="center"
            sx={{
              pb: 3,
              borderBottom: `1px solid ${variants[0].palette.primary.main}`,
            }}
          >
            {data.title}
          </Typography>
          <Box sx={{ mt: 5 }}>
            <Box
              sx={{
                height: "200px",
                width: "200px",
                bordeRadius: "13px",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <img
                src={data.coverImage?.fileUrl ?? "/default-image.png"}
                alt={data.coverImage?.fileName ?? "default"}
                style={{ ...imageDefaultStyleCover, borderRadius: "13px" }}
              />
            </Box>
            <Typography variant="h5" align="center" mt={2}>
              Cover Image
            </Typography>
          </Box>
          <Stack direction={"column"} sx={{ mt: 5, mb: 3 }}>
            <Typography variant="h5">Subtitle: </Typography>
            <Typography variant="body1">{data.subtitle}</Typography>
          </Stack>

          <Divider />

          <Stack mt={3} direction="row" justifyContent={"end"}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}
