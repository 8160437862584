import moment from "moment";

const Format = "DD-MMM-YYYY";
const FormatWithTime = "DD-MMM-YYYY hh:mm A";

export default function TimeFormat(timeString) {
  return moment(timeString * 1000).format(Format);
}

export function TimeFormatWithTime(timeString) {
  return moment(timeString * 1000).format(FormatWithTime);
}
