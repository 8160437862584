import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";

import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  Divider,
  CircularProgress,
  Grid,
  TextField as MuiTextField,
  Stack,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Typography,
  Autocomplete,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const SpecificProductAttributeForm = (props) => {
  // objects
  const { productAttribute, isButtonDisable, productId } = props;

  // form validation
  const validationSchema = Yup.object().shape({
    productAttributeName: Yup.string().required(
      "Product Attribute Name is required"
    ),
    value: Yup.string().required("Product Attribute Name Value is required"),
    priceIncrement: Yup.number().min(1).required("Price is required"),
    stock: Yup.boolean().required("is-in-Stock is required"),
  });

  // initial values
  const initialValues = {
    productAttributeName: productAttribute.productAttributeName || "",
    value: productAttribute.value || "",
    priceIncrement: productAttribute.priceIncrement || 0,
    stock: productAttribute.stock || true,
  };

  // submit form
  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await props.onSubmit(values);
      setStatus({ sent: true });
      setSubmitting(false);
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  // use-effect
  useEffect(() => {}, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        status,
        setFieldValue,
      }) => (
        <Card>
          {isSubmitting ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit}>
              <Grid container>
                <Grid item xs={12}>
                  <Stack direction="row" gap={3}>
                    <FormControl sx={{ my: 2 }} fullWidth size="small">
                      <InputLabel id="select-product-attribute">
                        Product Attribute Name
                      </InputLabel>
                      <Select
                        fullWidth
                        label="Product Attribute Name"
                        name="productAttributeName"
                        labelId="select-product-attribute"
                        value={values.productAttributeName || ""}
                        onChange={handleChange}
                      >
                        <MenuItem value={""} selected>
                          Select Attribute
                        </MenuItem>

                        <MenuItem value={"COLOR"}>Color</MenuItem>

                        <MenuItem value={"SIZE"}>Size</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      size="small"
                      name="value"
                      label="Product Attribute Value"
                      value={values.value}
                      error={Boolean(touched.value && errors.value)}
                      helperText={touched.value && errors.value}
                      fullWidth
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    size="small"
                    type="number"
                    name="priceIncrement"
                    label="Price"
                    value={values.priceIncrement}
                    error={Boolean(
                      touched.priceIncrement && errors.priceIncrement
                    )}
                    helperText={touched.priceIncrement && errors.priceIncrement}
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl sx={{ my: 2 }} size="small" fullWidth>
                    <InputLabel id="select-stock">Is In Stock</InputLabel>
                    <Select
                      name="stock"
                      labelId="select-stock"
                      value={values.stock}
                      error={Boolean(touched.stock && errors.stock)}
                      fullWidth
                      label="Is In Stock"
                      onChange={handleChange}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Stack justifyContent="end" direction="row" gap={4}>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      props.navigate(`/product/${productId}/attribute`)
                    }
                    mt={3}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={isButtonDisable}
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </form>
          )}
        </Card>
      )}
    </Formik>
  );
};

export default SpecificProductAttributeForm;
