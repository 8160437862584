import React, { useState } from "react";
import {
  Box,
  Modal,
  IconButton,
  Button,
  Stack,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableRow,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import Loader from "../ui/Loader";

import {
  activeIndicateColor,
  imageDefaultStyleContain,
  inActiveIndicateColor,
  sxFancyTableBorder,
  sxTableActionButtonBorder,
} from "../../theme/globalStyles";
import { customGreen } from "../../theme/variants";
import HtmlParser from "react-html-parser";
import { getBoardMemberById } from "../../api/boardMember";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "calc(100% - 30vw)",
  maxHeight: "calc(100% - 20vh)",
  overflowY: "scroll",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 10,
  borderRadius: "24px",
  "::-webkit-scrollbar": {
    background: "transparent",
  },
  "::-webkit-scrollbar-thumb": {
    background: customGreen[100],
    borderRadius: "24px",
  },
};

export default function ViewBoardMember({ id }) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchBoardMember = () => {
    setLoading(true);
    getBoardMemberById(id)
      .then((response) => {
        setLoading(false);
        setData(response.data.payload);
        console.log(response.data.payload);
      })
      .catch((error) => handleClose());
  };

  const handleOpen = () => {
    setOpen(true);
    fetchBoardMember();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sxTableCell = {
    py: 1,
    px: 2,
    fontSize: "15px",
    width: "100%",
  };

  return (
    <div>
      <IconButton
        color="secondary"
        sx={{ ...sxTableActionButtonBorder }}
        onClick={handleOpen}
      >
        <Visibility />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Stack direction="column" gap={10}>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  alignItems={"center"}
                  gap={10}
                >
                  <Box>
                    <Box
                      sx={{ width: "250px", height: "250px", margin: "auto" }}
                    >
                      <img
                        src={
                          data?.displayPhoto?.fileUrl ?? "/default-image.png"
                        }
                        alt=""
                        style={{
                          ...imageDefaultStyleContain,
                          borderRadius: "24px",
                          objectFit: "cover",
                        }}
                      />
                    </Box>
                  </Box>

                  <Stack
                    gap={{ xs: 5, md: 10 }}
                    justifyContent={{ xs: "start", md: "space-between" }}
                    alignItems={{ xs: "start", md: "center" }}
                    direction={{ xs: "column", md: "row" }}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <TableContainer
                      sx={{
                        height: { xs: "250px", lg: "100%" },
                        "&::-webkit-scrollbar": {
                          background: customGreen[100],
                          borderRadius: "5px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: customGreen[200],
                          borderRadius: "5px",
                        },
                      }}
                    >
                      <Table
                        sx={{
                          ...sxFancyTableBorder,
                          minWidth: "280px",
                          width: "100%",
                          minHeight: "250px",
                        }}
                      >
                        <TableBody sx={{ width: "100%" }}>
                          <TableRow sx={{ width: "100%" }}>
                            <TableCell sx={sxTableCell}>
                              <strong>Name:</strong>{" "}
                              {data?.name ?? "Unavailable"}
                            </TableCell>
                          </TableRow>
                          <TableRow sx={{ width: "100%" }}>
                            <TableCell sx={sxTableCell}>
                              <strong>Desgination:</strong>{" "}
                              {data?.designation ?? "Unavailable"}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>Email:</strong>{" "}
                              {data?.businessEmail ?? "Unavailable"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>Phone:</strong>{" "}
                              {data?.phone ?? "Unavailable"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>State:</strong>{" "}
                              {data?.state ?? "Unavailable"}
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            <TableCell sx={sxTableCell}>
                              <Stack
                                direction="row"
                                gap={1}
                                alignItems="center"
                              >
                                <strong>Status:</strong>{" "}
                                <div
                                  style={{
                                    height: "8px",
                                    width: "8px",
                                    borderRadius: "8px",
                                    backgroundColor: data.active
                                      ? activeIndicateColor
                                      : inActiveIndicateColor,
                                  }}
                                ></div>
                                {data.active ? "Active" : "Inactive"}
                              </Stack>
                            </TableCell>
                          </TableRow>
                          {/* <TableRow>
                            <TableCell sx={sxTableCell}>
                              <strong>Description:</strong>{" "}
                              {HtmlParser(data?.description) ?? "Unavailable"}
                            </TableCell>
                          </TableRow> */}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                </Stack>

                <TableContainer
                  sx={{
                    height: { xs: "250px", lg: "100%" },
                    "&::-webkit-scrollbar": {
                      background: customGreen[100],
                      borderRadius: "5px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: customGreen[200],
                      borderRadius: "5px",
                    },
                  }}
                >
                  <Table
                    sx={{
                      ...sxFancyTableBorder,
                      minWidth: "280px",
                      width: "100%",
                      pt: 5,
                    }}
                  >
                    <TableBody sx={{ width: "100%" }}>
                      <TableRow>
                        <TableCell sx={sxTableCell}>
                          <strong>Description:</strong>{" "}
                          {HtmlParser(data?.description) ?? "Unavailable"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
              <Stack direction="row" justifyContent={"end"} mt={3}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleClose()}
                >
                  Close
                </Button>
              </Stack>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
