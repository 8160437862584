import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { useMessage } from "../../../hooks/useMessage";
import { useSelector } from "react-redux";
import filterParams from "../../../utils/params";

import {
  Box,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import {
  createProductAttribute,
  getProductAttributeById,
  updateProductAttribute,
} from "../../../api/productAttribute";
import { getAllProducts } from "../../../api/product";
import SpecificProductAttributeForm from "../../../components/form/SpecificProductAttributeForm";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const EditSpecificProductAttribute = () => {
  // states
  const [productAttribute, setProductAttribute] = useState({});

  // objects
  const navigate = useNavigate();
  const message = useMessage();
  const location = useLocation();
  // const params = useParams();
  // const productAttributeId = params?.id;
  const { id, productAttributeId } = useParams();

  // functions
  // api calling
  const fetchProductAttribute = () => {
    if (productAttributeId) {
      getProductAttributeById(productAttributeId).then((response) => {
        const responseData = response.data.payload;
        console.log("get product att. response: ", responseData);
        setProductAttribute(responseData);
      });
    }

    console.log("Product Attribute id: ", productAttributeId);
    console.log("ProductId: ", id);
  };

  const submitProductAttribute = (values) => {
    console.log("Values: ", values);
    const data = {
      productAttributeId: productAttributeId,
      productAttributeName: values.productAttributeName.toUpperCase(),
      value: values.value,
      priceIncrement: values.priceIncrement,
      productId: id,
      stock: values.stock,
    };

    if (productAttributeId) {
      updateProductAttribute(data)
        .then((response) => {
          console.log("Product Attribute Updated!");
          return navigate(`/product/${id}/attribute`);
        })
        .catch((error) => console.log(error.response.data.message));
    }
  };

  useEffect(() => {
    fetchProductAttribute();
  }, []);

  return (
    <React.Fragment>
      <Helmet title={"Update Product Attribute"} />
      <Typography variant="h3" gutterBottom display="inline">
        Update Product Attribute
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb">
        <Link component={NavLink} to={`/product/${id}/attribute`}>
          Product Attribute
        </Link>
        <Typography>Update</Typography>
      </Breadcrumbs>

      <Divider my={3} />

      <Grid container spacing={6} justifyContent={"center"}>
        <Grid item xs={12} md={6}>
          <Card p={8}>
            <Typography variant="h3" mb={1} textAlign={"center"}>
              Update Product Attribute
            </Typography>
            <Divider sx={{ my: 3 }} />
            <SpecificProductAttributeForm
              navigate={navigate}
              productId={id}
              productAttribute={productAttribute}
              onSubmit={submitProductAttribute}
            />
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EditSpecificProductAttribute;
