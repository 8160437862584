import axios from "../utils/axios";

// change password
export const changePasswordApi = (data) => {
  return axios.post("api/auth/changepassword", data);
};

// reset password
export const forgetPassword = (data) => {
  return axios.post("api/auth/forget-password", data);
};

// sign-up
export const signUp = (data) => {
  return axios.post("api/auth/signup", data);
};
