import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogId: null,
  blogTitle: null,
};

export const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setBlog: (state, action) => {
      state.blogId = action.payload.blogId;
      state.blogTitle = action.payload.blogTitle;
    },
  },
});

export const { setBlog } = blogSlice.actions;

export default blogSlice.reducer;
